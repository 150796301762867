import template from './index.pug'
import videoEmbed from '../../helpers/video-embed'
import { mapGetters } from 'vuex'


export default Vue.extend({
  name: 'helper-video-modal',
  template: template(),
  components: {videoEmbed},
  computed:{
    ...mapGetters(['getVideoUrl'])
  },
  methods: {
    close(){
      this.$emit('close')
    }
  }
})