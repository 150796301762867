// Requiere que ya esté cargado vuex en la instancia de Vue
// Requiere que ya existe vue-i18n

const LanguagePlugin = {
  install(Vue) {
    // Idioma seleccionado en el navegador
    const languageStorageKey = 'app-language'

    // Idioma por defecto de flatpickr
    const flatpickrDefaultLanguage = 'en'

    // Idiomas disponibles
    const availableLanguages = Vue.store.state.languages

    // Idioma por defecto
    const defaultLanguage = availableLanguages[0]

    // Recupera el idioma del alamacenamiento local
    const getStoredLanguage = () => localStorage.getItem(languageStorageKey)

    // Recupera el idioma de la store
    const getLanguage = () => Vue.store.state.language

    // Guarda el idioma en el almacenamiento local
    const setStoredLanguage = language => {
      localStorage.setItem(languageStorageKey, language)
    }

    // Método para cargar un idioma en flatpickr
    const getFlatpickerLanguage = language => require(
      `flatpickr/dist/l10n/${language}.js`
    ).default[language]

    // Método para cargar el idioma por defecto en flatpickr
    const getFlatpickerDefaultLanguage = () => require('flatpickr/dist/l10n/default.js').default

    // Método para cambiar el idioma de flatpickr
    const changeFlatpickrLanguage = language => {
      let languageConfig
      if (language === flatpickrDefaultLanguage) {
        languageConfig = getFlatpickerDefaultLanguage()
      } else {
        try {
          languageConfig = getFlatpickerLanguage(language)
        } catch (error) {
          console.error(error)
          console.error('El lenguaje no existe')
        }
      }
      // Flatpickr está globalmente en toda la aplicación y requiere un reload de la página
      flatpickr.localize(languageConfig)
    }

    // Método para cambiar el idioma de nuestra app
    const changeAppLanguage = language => {
      Vue.i18n.locale = language
    }

    // Método para cambiar el idioma de moment
    const changeMomentLanguage = language => {
      moment.locale(language)
    }

    // Carga el idioma seleccionado y lo configura en la plataforma
    const loadStoredLanguage = () => {
      let storedLanguage = getStoredLanguage()

      if (!storedLanguage) {
        // Inicializar idioma de la app
        setStoredLanguage(defaultLanguage)
        storedLanguage = getStoredLanguage()
      }

      const language = storedLanguage.toLowerCase()

      Vue.store.commit('setLanguage', language)
      changeAppLanguage(language)
      changeMomentLanguage(language)
      changeFlatpickrLanguage(language)
    }

    // Cambia todo el idioma de la plataforma
    const changeLanguage = language => {
      setStoredLanguage(language)
      loadStoredLanguage()
    }

    const language = {
      availableLanguages,
      loadStoredLanguage,
      changeLanguage,
      getLanguage
    }

    Vue.language = language
    Vue.prototype.$language = language

    Vue.language.loadStoredLanguage()
  }
}

Vue.use(LanguagePlugin)
