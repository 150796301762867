Vue.filter('selectSublineName', subline => {
  if (subline) {
    const name = subline.name
    const extension = subline.extension ? ` ${subline.extension}` : ''
    const description = subline.description ? ` | ${subline.description}` : ''

    return `${name}${extension}${description}`
  }
  return ''
})
