import Vue from 'vue'
import template from './index.pug'

// @vue/component
Vue.component('sidepanel', {
  name: "side-panel",
  template: template(),

  props: {
    open: {
      type: Boolean,
      default: false
    },

    classType: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      internalOpen: false
    }
  },

  computed: {
    openClass() {
      return this.internalOpen ? 'is-open' : ''
    }
  },

  watch: {
    open(newOpen) {
      this.assignOpen(newOpen)
    }
  },

  created() {
    this.assignOpen(this.open)
  },

  mounted() {
    this.calculateHeight()
  },

  methods: {
    toggleOpen() {
      this.internalOpen = !this.internalOpen
      if (this.internalOpen) {
        this.$emit('open')
      } else {
        this.$emit('close')
      }
      this.$emit('change-open', this.internalOpen)
    },

    assignOpen(newOpen) {
      if (this.internalOpen !== newOpen) {
        this.internalOpen = newOpen
      }
    },

    // Cálculo de altura para permitir scroll en dispositivos iOS antiguos
    calculateHeight() {
      const item = document.getElementsByClassName('sidepanel-map__content')[0]
      item.style.height = `${item.scrollHeight}px`
    }
  }
})
