function beforeWatchPosition(to, from, next) {
  const {watchPosition} = to.meta
  const {dispatch, commit, getters: {watchPositionId}} = Vue.store

  commit('resetPositionError')

  if (watchPosition) {
    if (!watchPositionId) {
      dispatch('watchUserPosition')
    }
  } else if (watchPositionId) {
    dispatch('clearWatchUserPosition')
  }

  next()
}

export default beforeWatchPosition
