/* eslint-disable linebreak-style */
const TELEPHONE = {
  href: 'tel:+351300074830',
  number: '300 074 830'
}

const DEFAULT = -1

const COMPLAINTS_URL = 'https://www.livroreclamacoes.pt/Inicio/'

const SOCIAL_MEDIA = [
  {
    scope: DEFAULT,
    rrss: [{
      name: 'Youtube',
      icon: 'youtube',
      link: 'https://www.youtube.com/channel/UC8_3RotA5zq5ItiefKV1cew'
    },
    {
      name: 'Facebook',
      icon: 'facebook',
      link: 'https://www.facebook.com/vamusalgarve'
    },
    {
      name: 'Instagram',
      icon: 'instagram',
      link: 'https://www.instagram.com/vamus_algarve/'
    }],
    logo: [{
      name: 'Vamus Algarve',
      icon: require('assets/images/logo.svg'),
      image: true,
      link: 'homeRoute'
    }],
    network_map: ['https://samoverickpro.blob.core.windows.net' +
    '/static-files/icons/vz/VAMUS-MAPA_SITE.png'],
    order: -1
  },
  {
    scope: 15,
    rrss: [{
      name: 'Youtube',
      icon: 'youtube',
      link: 'https://www.youtube.com/channel/UC8_3RotA5zq5ItiefKV1cew'
    },
    {
      name: 'Facebook',
      icon: 'facebook',
      link: 'https://www.facebook.com/vamusalgarve'
    },
    {
      name: 'Instagram',
      icon: 'instagram',
      link: 'https://www.instagram.com/vamus_algarve/'
    }],
    logo: [{
      name: 'Lagos',
      icon: require('assets/images/onda.svg'),
      image: true,
      link: 'homeRoute'
    }],
    network_map: ['https://samoverickpro.blob.core.windows.net' +
    '/static-files/icons/vz/rede_urbana_aonda.png'],
    order: -1
  },
  {
    scope: 11,
    rrss: [{
      name: 'Youtube',
      icon: 'youtube',
      link: 'https://www.youtube.com/channel/UC8_3RotA5zq5ItiefKV1cew'
    },
    {
      name: 'Facebook',
      icon: 'facebook',
      link: 'https://www.facebook.com/vamusalgarve'
    },
    {
      name: 'Instagram',
      icon: 'instagram',
      link: 'https://www.instagram.com/vamus_algarve/'
    }],
    logo: [{
      name: 'Portimao',
      icon: require('assets/images/portimao.svg'),
      image: true,
      link: 'homeRoute'
    }],
    network_map: ['https://samoverickpro.blob.core.windows.net' +
    '/static-files/icons/vz/mapa_vaievem.png'],
    order: -1
  },
  {
    scope: 6,
    rrss: [{
      name: 'Youtube',
      icon: 'youtube',
      link: 'https://www.youtube.com/channel/UC8_3RotA5zq5ItiefKV1cew'
    },
    {
      name: 'Facebook',
      icon: 'facebook',
      link: 'https://www.facebook.com/vamusalgarve'
    },
    {
      name: 'Instagram',
      icon: 'instagram',
      link: 'https://www.instagram.com/vamus_algarve/'
    }],
    logo: [{
      name: 'Olhão',
      icon: require('assets/images/olhao.svg'),
      image: true,
      link: 'homeRoute'
    }],
    network_map: '',
    order: -1
  },
  {
    scope: 5,
    rrss: [{
      name: 'Youtube',
      icon: 'youtube',
      link: 'https://www.youtube.com/channel/UC8_3RotA5zq5ItiefKV1cew'
    },
    {
      name: 'Facebook',
      icon: 'facebook',
      link: 'https://www.facebook.com/vamusalgarve'
    },
    {
      name: 'Instagram',
      icon: 'instagram',
      link: 'https://www.instagram.com/vamus_algarve/'
    }],
    logo: [{
      name: 'Vamus Algarve',
      icon: require('assets/images/logo.svg'),
      image: true,
      link: 'homeRoute'
    }],
    network_map: ['https://samoverickpro.blob.core.windows.net' +
    '/static-files/icons/vz/VAMUS-MAPA_SITE.png'],
    order: -1
  },
  {
    scope: 16,
    rrss: [{
      name: 'Youtube',
      icon: 'youtube',
      link: 'https://www.youtube.com/channel/UC8_3RotA5zq5ItiefKV1cew'
    },
    {
      name: 'Facebook',
      icon: 'facebook',
      link: 'https://www.facebook.com/vamusalgarve'
    },
    {
      name: 'Instagram',
      icon: 'instagram',
      link: 'https://www.instagram.com/vamus_algarve/'
    }],
    logo: [{
      name: 'Albufeira',
      icon: require('assets/images/giro.svg'),
      image: true,
      link: 'homeRoute'
    }],
    network_map: ['https://samoverickpro.blob.core.windows.net' +
    '/static-files/icons/vz/Mapa_Giro-1.png',
    'https://samoverickpro.blob.core.windows.net' +
    '/static-files/icons/vz/Mapa_Giro-2.png'],
    order: -1
  }

]

const ADDRESS = null

const EMAIL_ADDRESS = {
  username: 'clientes',
  domain: 'vamusalgarve.pt'
}

const GENERAL_TRANSPORT_CONDITIONS_URL = 'https://moverickeuropastorage.blob.core.windows.net/' +
                                         'static-files/condiciones_de_transporte_vz.pdf'

export {
  TELEPHONE,
  COMPLAINTS_URL,
  SOCIAL_MEDIA,
  ADDRESS,
  EMAIL_ADDRESS,
  GENERAL_TRANSPORT_CONDITIONS_URL,
  DEFAULT
}
