import Vue from 'vue'
import {mapGetters} from 'vuex'

import template from './_app-logo.pug'

const Logo = require('assets/images/logo.svg')

// @vue/component
export default Vue.extend({
  template: template(),
  props: {
    logoUrl: {
      default: '---',
      type: String,
      required: true

    },
    altImage: {
      default: '---',
      type: String,
      required: false
    }
  },

  data() {
    return {
      logo: Logo
    }
  },

  computed: {
    ...mapGetters(['homeRoute'])
  },

  methods: {
    goToHome() {
      window.location.replace('/')
      // this.$emit('click')
      // this.$router.push(this.homeRoute)
    }
  }
})
