import customAccesibilityTabs from '../../../../config/before-mount/accesibility-tab-navigation'

export default ({
  
  state: {
    accessibilityModalState: false
  },
  
  mutations: {
    CHANGE_ACCESSIBILITY_MODAL_STATE(state, value){
      state.accessibilityModalState = value
    }
  },
  
  getters: {
    getAccessibilityModalState: state => state.accessibilityModalState
  },
  
  actions: {
    showAccessibilityModal({commit}){
      commit('CHANGE_ACCESSIBILITY_MODAL_STATE', true)
    },
    closeAccessibilityModal({commit}){
      commit('CHANGE_ACCESSIBILITY_MODAL_STATE', false)
      customAccesibilityTabs.setup('vuex-language-modal-vuex-action')
    }
  }

})