import customAccesibilityTabs from '../../../../config/before-mount/accesibility-tab-navigation'

export default ({
  state: {
    userLanguage: localStorage.getItem('app-language') ?? 'es',
    showLanguageModal: false
  },
  mutations: {
    SET_SHOW_LANGUAGE_MODAL(state, value) {
      state.showLanguageModal = value
    }
  },
  getters: {
    showLanguageModal: state => state.showLanguageModal,
    getUserLanguage: state => state.userLanguage
  },
  actions: {
    openLanguageModal({commit}) {
      commit('SET_SHOW_LANGUAGE_MODAL', true)
    },

    closeLanguageModal({commit}) {
      commit('SET_SHOW_LANGUAGE_MODAL', false)
      customAccesibilityTabs.setup('vuex-language-modal-vuex-action')
    }
  }
})
