import SelectOptions from 'js/mixins/contact-modal-select-options'

export default {
  data() {
    return {
      showContactModal: false,
      selectOptions: SelectOptions.map(option => (
        {label: `contact.motives.${option}`, value: option}
      )),
      contactTitle: 'contact.title'
    }
  },

  methods: {
    openContactModal() {
      this.$router.push({...this.$route, query: {openContactModal: 'true'}})
    },

    closeContactModal() {
      this.$router.push({...this.$route, query: {openContactModal: undefined}})
    }
  }
}
