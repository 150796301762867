export default({

  state:{
    showHelperVideo: false,
    // helperVideoUrl: "https://youtu.be/9YffrCViTVk?si=TyXVFPzAMUiiQCr3"
    helperVideoUrl: "https://www.youtube.com/embed/NKLLpRDdGSg"
  },

  mutations: {
    CHANGE_STATE(state, value){
      state.showHelperVideo = value
    }
  },

  getters: {
    getHelperVideoState(state){
      return state.showHelperVideo
    },
    getVideoUrl(state){
      return state.helperVideoUrl
    }
  },

  actions: {
    showHelperVideo({commit}){
      commit('CHANGE_STATE', true)
    },
    
    hideHelperVideo({commit}){
      commit('CHANGE_STATE', false)
    }
  }
})

