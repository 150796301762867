import TicketingAdministrativeAreasService from 'js/services/api/ticketing/administrative-areas.js'

export default ({
  state: {
    administrativeArea: null
  },
  mutations: {
    setAdministrativeArea(state, administrativeArea) {
      state.administrativeArea = administrativeArea
    }
  },
  getters: {
    hasAdministrativeArea(state) {
      return state.administrativeArea !== null
    },
    administrativeArea(state) {
      return state.administrativeArea || {}
    },
    administrativeAreaId(_, getters) {
      return getters.administrativeArea.id
    },
    administrativeAreaTimeZone(state) {
      return (state.administrativeArea || {}).time_zone
    }
  },
  actions: {
    async loadAdministrativeArea({commit}, {administrativeAreaId}) {
      const params = {
        template: 'with_parameters_with_counter'
      }

      const administrativeArea = await TicketingAdministrativeAreasService.show(
        administrativeAreaId, params
      )

      commit('setAdministrativeArea', administrativeArea)
    },
    async loadAdministrativeAreaFromMunicipality({commit}, {municipalityId}) {
      const params = {
        municipality_id: municipalityId,
        template: 'with_parameters_with_counter',
        per: 1
      }

      const {data: administrativeAreas} = await TicketingAdministrativeAreasService.index(params)

      const administrativeArea = administrativeAreas[0]

      commit('setAdministrativeArea', administrativeArea)
    }
  }
})
