const AuthPlugin = {
  install(Vue) {
    // Token de sesión
    const token = () => localStorage.getItem('moverick-passenger-session-token')

    const setToken = newToken => {
      localStorage.setItem('moverick-passenger-session-token', newToken)
    }

    const setUser = user => {
      Vue.store.commit('setCurrentUser', user)
    }

    const login = data => Vue.http.post('session', {data}, {localeRootKey: 'auth'})

    const logout = () => {
      Vue.http.delete('session').then(() => {
        setToken('')
        setUser(undefined)
      })
    }

    const resetPassword = data => {
      const options = {localeRootKey: 'auth'}

      return Vue.http.post('clients/password', {data}, options).then(response => response.data.data)
    }

    const registration = data => Vue.http.post('clients', {data}).then(
      response => response.data.data
    )

    const unregister = params => Vue.http.delete('client', {params}).then(
      response => response.data.data
    )

    const update = (data, skipLoading = false) => {
      const options = {skipLoading, localeRootKey: 'auth'}

      return Vue.http.put('client', {data}, options).then(response => response.data.data)
    }

    const updatePassword = data => {
      const options = {localeRootKey: 'auth'}

      return Vue.http.put('client/update_password', {data}, options).then(
        response => response.data.data
      )
    }

    const auth = {
      token,
      setToken,
      setUser,
      login,
      logout,
      unregister,
      resetPassword,
      registration,
      update,
      updatePassword/* ,
      unregister(params) {
        return Vue.http.delete('client', {params}).then(response => {
          return response.data.data;
        });
      }
      /* confirmation(data) {
        return Vue.http.put('confirmation', data);
      },
      resendConfirmation(data) {
        return Vue.http.post('confirmation', data);
      }
      */
    }

    Vue.auth = auth
    Vue.prototype.$auth = auth
  }
}

Vue.use(AuthPlugin)
