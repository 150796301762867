export default {
  googlePlayLink: {
    id: 'google-play',
    image: 'google-play-badge.png',
    altText: 'googlePlay'
  },
  appleStoreLink: {
    id: 'app-store',
    image: 'app-store-badge.svg',
    altText: 'appStore'
  }
}
