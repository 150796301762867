export default {
  show(id, params) {
    return Vue.http.get(`ticketing/administrative_areas/${id}`, {params}).then(
      response => response.data.data
    )
  },
  async index(params) {
    const response = await Vue.http.get('ticketing/administrative_areas', {params})
    return response.data
  },
  showConfig(id) {
    return Vue.http.get(`ticketing/administrative_areas_config/${id}`).then(
      response => response.data.data
    ) 
  }
}
