import Vue from 'vue'
import {mapActions,mapGetters} from 'vuex'

import AppLogos from 'js/components/layout-footer/_app-logos.js'
import ContactMixin from 'js/mixins/contact-modal-mixin'
import AddressInfo from 'js/components/address-info/index.js'
import PhoneNumber from 'js/components/phone-number/index.js'
import ComplaintsBook from 'js/components/complaints-book/index.js'
import ComplaintsBook2 from 'js/components/complaints-book2/index.js'
import FollowUs from 'js/components/follow-us/index.js'

import template from './index.pug'

Vue.component('layout-footer', {
  name: 'layout-footer-custom-vz',
  template: template(),

  components: {
    AppLogos,
    AddressInfo,
    PhoneNumber,
    ComplaintsBook,
    FollowUs,
    ComplaintsBook2
    
  },

  mixins: [ContactMixin],

  data() {
    return {}
  },
  computed: {
    ...mapGetters(["contraste"]),
  },
  methods: {
    ...mapActions(['openLanguageModal']),

    goToTransportRequest() {
      this.$router.push({name: 'transport-request'})
    },

    goToPrivacyPolicy() {
      const routeData = this.$router.resolve({name: 'privacy-policy'})
      window.open(routeData.href)
    },

    goToGeneralConditionsOfTransport() {
      const routeData = this.$router.resolve({
        name: 'general-conditions-of-transport'
      })
      window.open(routeData.href)
    },

    goToCookiesPolicyText() {
      const routeData = this.$router.resolve({
        name: 'cookies-policy-text'
      })
      window.open(routeData.href)
    },

    goInstitutionalInformation() {
      const routeData = this.$router.resolve({
        name: 'institutional-information'
      })
      window.open(routeData.href)
    },

    goToBolsa() {
      try {
        const language = navigator.language
        const url = 'https://eva-bus.com/upload/frm_inscricao.php' +
          '?t=Motorista%20de%20pesados%20de%20passageiros'
        if (language == null) {
          window.open(url)
        } else if (language.includes('es')) {
          window.open(url)
        } else if (language.includes('pt')) {
          window.open(url)
        } else {
          window.open(url)
        }
      } catch (err) {
        window.open(url)
      }
    },
    goToDenuncia() {
      try {
        const language = navigator.language
        const url = 'https://transportesdoalgarve.integrityline.com/'
        if (language == null) {
          window.open(url)
        } else if (language.includes('es')) {
          window.open(url)
        } else if (language.includes('pt')) {
          window.open(url)
        } else {
          window.open(url)
        }
      } catch (err) {
        window.open(url)
      }
    },
    goToPressNews() {
      const routeData = this.$router.resolve({
        name: 'press-news'
      })
      window.open(routeData.href)
    },

    goToPrivacyPolicyText() {
      const routeData = this.$router.resolve({
        name: 'privacy-policy-text'
      })
      window.open(routeData.href)
    },

    goToAccessibilityStatement() {
      const routeData = this.$router.resolve({
        name: 'accessibility-statement'
      })
     
      window.open(routeData.href)
    }
  }
})
