import MapPublicRoutes from 'js/vue-plugins/vue-router/routes/municipalities/map-public-routes'
import PublicRoutes from 'js/vue-plugins/vue-router/routes/municipalities/public-routes'
import PrivateRoutes from 'js/vue-plugins/vue-router/routes/municipalities/private-routes'

import modify_municipality_news_path from './modify-municipality-news-path'

const Municipality = () => import('js/pages/municipality/index.js')

modify_municipality_news_path(PublicRoutes)

export default {
  path: '/:country/:municipality',
  name: 'municipality',
  component: Municipality,
  redirect: {name: HOME_ROUTE},
  children: [].concat(
    MapPublicRoutes,
    PublicRoutes,
    PrivateRoutes
  )
}
