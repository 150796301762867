const NearestsStops = () => import('js/pages/nearests-stops/index.js')
const Routes = () => import('js/pages/routes/index.js')
const Stops = () => import('js/pages/stops/index.js')
const PointOfSales = () => import('js/pages/point-of-sales/index.js')
const Planner = () => import('js/pages/planner/index.js')

const meta = {
  fixedLayout: true,
  watchPosition: true
}

export default [
  {
    path: 'nearests-stops',
    name: 'municipality-nearests-stops',
    component: NearestsStops,
    meta
  },
  {
    path: 'routes',
    name: 'municipality-routes',
    component: Routes,
    meta
  },
  {
    path: 'stops',
    name: 'municipality-stops',
    component: Stops,
    meta
  },
  {
    path: 'point-of-sales',
    name: 'municipality-point-of-sales',
    component: PointOfSales,
    meta
  },
  {
    path: 'planner',
    name: 'municipality-planner',
    component: Planner,
    meta
  }
]
