import VueRouter from 'vue-router'

import routes from 'js/vue-plugins/vue-router/routes/index'
import beforeEachs from 'js/vue-plugins/vue-router/before-each/index'

// Le indicamos a Vue que use VueRouter
Vue.use(VueRouter)

// Crear la instancia del router con las rutas configuradas
const router = new VueRouter({
  routes,
  scrollBehavior(to) {
    if (to.hash) { return {selector: to.hash} }

    return undefined
  }
})

// Carga de before eachs del router
beforeEachs.forEach(beforeEach => {
  router.beforeEach(beforeEach)
})

Vue.router = router

export default router
