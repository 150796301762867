import {GENERAL_TRANSPORT_CONDITIONS_URL} from 'js/lib/info.js'

const transport_conditions_route = {
  path: 'news/:slug?',
  name: 'transport-conditions',
  beforeEnter(to, _from, next) {
    // Para los enlaces que hayan compartido en RRSS, mantenemos funcionalidad
    const {params} = to
    if (params.slug) {
      next({name: 'municipality-news', params})
    } else {
      window.location.href = GENERAL_TRANSPORT_CONDITIONS_URL
    }
  }
}

export default function modify_municipality_news_path(routes) {
  routes.find(route => route.name === 'municipality-news').path = 'notices/:slug?'
  routes.push(transport_conditions_route)
}
