// Requiere que existan vue-i18n y vuex
import Vue from 'vue'

const key = Vue.store.state.map.googleMapsApiKey
const language = Vue.i18n.locale
const params = `key=${key}&language=${language}&libraries=places`

const scriptSrc = `https://maps.googleapis.com/maps/api/js?${params}`
const scriptId = 'googleMapsScript'

const googleMapsScript = document.createElement('script')
googleMapsScript.setAttribute('id', scriptId)
googleMapsScript.setAttribute('src', scriptSrc)
googleMapsScript.setAttribute('async', '')
googleMapsScript.setAttribute('defer', '')
document.body.appendChild(googleMapsScript)
