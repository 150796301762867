import Vue from 'vue'
import template from './index.pug'

export default Vue.extend({

  template: template(),

  data() {
    return {
      distribusionLoaded: false
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.distribusionFunction()
    })
  },

  methods: {
    /**
     * Implements the distribusion library as described in the documentation but uses the Virtual DOM
     * @returns void
     */
    distribusionFunction() {
      // If the component is already loaded we won't try to load it again
      if(this.distribusionLoaded) return 

      // Connect to the element created with Vue instead of a DOM object which may not exist yet
      const root = this.$refs.distribusion 
      Distribusion.Search.mount({
        root,
        partnerNumber: 890815,
        locale: Vue.language.getLanguage(),
        currency: 'EUR', // optional
        layout: 'row',
        defaults: { // optional
          departureStation: '', // or
          departureArea: '', // or
          departureCity: '',

          arrivalStation: '', // or
          arrivalArea: '', // or
          arrivalCity: ''
        }
      })

      // Mark the component as loaded so it can't be loaded again 
      this.distribusionLoaded = true 
    }
  }

})
