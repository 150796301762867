import municipalities from 'js/vue-plugins/vue-router/before-each/municipalities'
import auth from 'js/vue-plugins/vue-router/before-each/auth'
import watchPosition from 'js/vue-plugins/vue-router/before-each/watch-position'
import appConfig from 'js/vue-plugins/vue-router/before-each/app-config'

export default [
  municipalities,
  auth,
  watchPosition,
  appConfig
]
