import Vue from 'vue'
import {mapGetters} from 'vuex'

// Cada app puede tener su propia selección de logos
import Logos from 'js/components/layout-footer/_logos'

import template from './_app-logos.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  data() {
    return {
      logos: Logos
    }
  },

  computed: {
    ...mapGetters(['homeRoute'])
  },

  methods: {
    goToHome() {
      window.location.replace('/')
      // this.$emit('click')
      // this.$router.push(this.homeRoute)
    }
  }
})
