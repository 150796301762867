const Base = () => import('js/pages/municipality-data/base.js')
const BalanceIndex = () => import('js/pages/municipality-data/balance/index.js')
const BalanceTnesNew = () => import('js/pages/municipality-data/balance/account/tnes/new.js')
const PaymentMedias = () => import('js/pages/municipality-data/payment-medias/index.js')
const Travels = () => import('js/pages/municipality-data/travels/index.js')

const Products = () => import('js/pages/municipality-data/travels/index.js')
const Tscs = () => import('js/pages/municipality-data/travels/index.js')

const authLayoutInfoMeta = {
  layoutInfo: true,
  layout: 'default',
  auth: true
}

export default {
  path: 'data',
  name: 'municipality-data',
  component: Base,
  redirect: {name: 'municipality-data-travels'},
  meta: Base,
  children: [
    {
      path: 'balance',
      name: 'municipality-data-balance',
      component: BalanceIndex,
      meta: authLayoutInfoMeta,
      children: [

        {
          path: ':id/tnes/new',
          name: 'municipality-data-balance-tnes-new',
          component: BalanceTnesNew,
          meta: authLayoutInfoMeta
        },

      ]
    },
    {
      path: 'payment-medias',
      name: 'municipality-data-payment-medias',
      component: PaymentMedias,
      meta: authLayoutInfoMeta
    },
    {
      path: 'travels',
      name: 'municipality-data-travels',
      component: Travels,
      meta: authLayoutInfoMeta
    },
    {
      path: 'travels',
      name: 'municipality-data-products',
      component: Products,
      meta: authLayoutInfoMeta
    },
    {
      path: 'travels',
      name: 'municipality-data-tscs',
      component: Tscs,
      meta: authLayoutInfoMeta
    }
  ]
}
