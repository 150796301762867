import VueResource from 'vue-resource'

// Interceptors
import interceptors from 'js/vue-plugins/vue-resource/interceptors'

Vue.use(VueResource)

Vue.http.options.root = API_HOST
Vue.http.headers.common['X-Passenger-App'] = 'true'
Vue.http.headers.common['X-Passenger-App-Code'] = Vue.store.state.appCode
Vue.http.headers.common['Accept-Language'] = Vue.store.state.language

// Carga de interceptors
interceptors.forEach(interceptor => {
  Vue.http.interceptors.push(interceptor)
})
