import customAccesibilityTabs from "../config/before-mount/accesibility-tab-navigation"
import {mapActions, mapGetters, mapMutations} from 'vuex'
export default {
  data(){
    return {
      customAccesibilityTabs
    }
  },

  computed :{
    ...mapGetters(['allComponentsMounted', 'contraste'])
  },

  watch: {
    allComponentsMounted(newVal){
      return newVal
    },

    contraste(newVal, oldVal){
      // console.log(`contrast has changed from ${oldVal} to ${newVal}`)
      this.applyContrasteCss()
    }
  },

  methods: {
    
    ...mapActions([
      'escucharAudio', 
      'componentMounted', 
      'checkAccessibilityTabs'
    ]),
    ...mapMutations(['registerComponent']),

    /**
       * Looks for the nearest input within the event target and applies focus
       * If a selector is provided then this element will be used for the search of the nested items
       * By default we will search for input:first
       * @param {Event} event 
       * @param {String} selector Optional valid DOM selector (jQuery)
       * @return void
       */
    targetInput(event, selector=null, fromParent=false) {
      selector = selector === null ? 'input:first' : selector
      let $input = fromParent 
        ? $(event.target).parent().find(selector)
        : $(event.target).find(selector)
      // console.log("input", $input);
      $input.focus()
      //$input.click()
    }, 

    /**
     * Captures all keyup events so they can be redirected as required.
     * If a key is not handled in the switch it is returned so bubbling can continue and usual behaviour can continue
     * @param {Event} event 
     * @returns ?Event 
     */
    handleKeystroke(event) {
      switch(event.code) {
        case 'Escape':
          this.$emit('close')
          break
        case 'Space':
        case 'Enter':
          event.preventDefault()
          // console.log('Keystroke', $(event.target))
          // console.log('Keystroke', $(event.target)[0].nodeName)
          $(event.target).click()
          break
        default:
          return
      }
    },

    createdBehaviour(){
      this.registerComponent(this.$options.name)
    },

    mountedBehaviour(){
      this.componentMounted(this.$options.name)
      this.applyContrasteCss()
    },

    applyContrasteCss(){
      if(this.$options.name !== undefined && !this.$options.name.includes('ns-')) {
        // console.log(`El método applyContrasteCss no esta sobre escrito en el componente: ${this.$options.name}`)
      }
    }
  },

  created(){
    this.createdBehaviour()
  },
  
  mounted(){
    this.$nextTick(() => {
      this.mountedBehaviour()
   })
  }
}