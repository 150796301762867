import Vue from 'vue'
import {mapActions} from 'vuex'
import template from './_app-link.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  props: {
    app: {
      type: Object,
      required: true
    },
    tabindex: {
      type: Number,
      default: 50,
      required: false
    }
  },

  computed: {
    appImage() {
      try {
        const logo = require(`assets/images/store/${this.app.image}`)
        return logo
      } catch (_error) {
        return undefined
      }
    }
  },

  methods: {
    ...mapActions(['escucharAudio' ]),
    openUrl() {
      window.open(this.app.url)
    }
  }
})
