export default ({
  state: {
    alpha2: COUNTRY_ALPHA2
  },
  getters: {
    countryAlpha2(state) {
      return state.alpha2
    }
  }
})
