const PrivacyPolicy = () => import('js/pages/privacy-policy/index.js')

const meta = {
  layoutInfo: true,
  headerHidden: true,
  layout: 'default'
}

export default [
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    meta
  }
]
