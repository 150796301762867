// Extrae los datos de la apps movil que se van a mostrar en la aplicación del fichero _links.js
// para que estos enlaces se muestren deben tener una url configurada desde la administración
// para el ámbito
import Vue from 'vue'
import {mapGetters} from 'vuex'
import availableApps from 'js/components/apps-links/_links.js'
import AppLink from './_app-link'
import template from './index.pug'

Vue.component('apps-links', {
  name: 'apps-links',
  template: template(),
  components: {AppLink},

  props: {
    appListClass: {
      type: String,
      default: 'mt-xs flex-center mb-d6-xs'
    },

    headerText: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {availableApps, currentApps: []}
  },

  computed: {
    ...mapGetters(['currentAppConfig']),

    computedHeaderText() {
      return this.headerText || this.$t('defaultLayout.download')
    }
  },
  watch: {
    currentAppConfig() {
      this.loadLinksUrl()
    }
  },
  created() {
    this.loadLinksUrl()
  },
  methods: {
    loadLinksUrl() {
      this.currentApps = []
      if (this.currentAppConfig.google_play_url) { this.addGooglePlayLink() }
      if (this.currentAppConfig.apple_store_url) { this.addAppleStoreLink() }
    },

    addGooglePlayLink() {
      this.addDownloadLink(this.availableApps.googlePlayLink, this.currentAppConfig.google_play_url)
    },

    addAppleStoreLink() {
      this.addDownloadLink(this.availableApps.appleStoreLink, this.currentAppConfig.apple_store_url)
    },

    addDownloadLink(store, url) {
      this.currentApps.push({...store, ...{url}})
    },

    notLastApp(index) {
      return index < this.currentApps.length - 1
    }
  }
})
