import {mapGetters} from 'vuex'

Vue.mixin(Vue.extend({
  computed: {
    ...mapGetters({
      $user: 'currentUser',
      $accounts: 'clientAccounts',
      $currentAccount: 'currentClientAccount',
      $municipality: 'currentMunicipality',
      $administrativeArea: 'administrativeArea',
      $administrativeAreaTimeZone: 'administrativeAreaTimeZone',
      $currentAppConfig: 'currentAppConfig',
      $device: 'device',
      $isPortable: 'isPortable',
      $loading: 'loading',
      countryAlpha2: 'countryAlpha2'
    })
  }
}))
