function auth(to, from, next) {
  // Si es zona privada y no hay token, mando al home
  if (to.meta.auth === true && !Vue.auth.token()) {
    next({name: 'home'})
  // Si hay token y no se ha cargado el usuario, se carga
  } else if (Vue.auth.token() && !Vue.store.state.currentUser) {
    Vue.store.dispatch('loadCurrentUser').then(() => { next() })
  // En caso contrario acudo a la nueva url
  } else {
    next()
  }
}

export default auth
