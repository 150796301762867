import Vue from 'vue'
import {ADDRESS, EMAIL_ADDRESS} from 'js/lib/info.js'

import template from './index.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  data() {
    return {
      address: ADDRESS,
      email_user: EMAIL_ADDRESS.username,
      email_domain: EMAIL_ADDRESS.domain
    }
  }
})
