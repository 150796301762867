import MunicipalityDataRoute from './data/index'

const Profile = () => import('js/pages/profile/index.js')

const meta = {
  layoutInfo: true,
  layout: 'default',
  auth: true
}

export default [
  {
    path: 'profile',
    name: 'municipality-profile',
    component: Profile,
    meta
  },
  MunicipalityDataRoute
]
