import Vue from 'vue'
import {SOCIAL_MEDIA, DEFAULT} from 'js/lib/info.js'

import {mapGetters, mapActions} from 'vuex'

import AppLogo from 'js/components/layout-menu/_app-logo.js'
import PhoneNumber from 'js/components/phone-number/index.js'
import SessionMenu from 'js/components/layout-menu/_session-menu.js'
import MunicipalitySelector from 'js/components/layout-menu/header/_municipality-selector.js'
import ContactMixin from 'js/mixins/contact-modal-mixin'
import LogoBlack from 'assets/images/logoBlack.svg'
import LogoOriginal from 'assets/images/logo.svg'
import IconoAccesibility from 'assets/images/iconoaccesibility.svg'
import IconoContraste from 'assets/images/iconoContraste.svg'
import IconoAccesibilityBlanco from 'assets/images/iconoaccessibilityblanco.svg'
import libroReclamoBlanco from 'assets/images/libroReclamoBlanco.svg'
import IconoVoz from 'assets/images/ico-voz.svg'
import IconoVozAzul from 'assets/images/ico-voz-azul.svg'


import template from './_full-header.pug'



// @vue/component
export default Vue.extend({
  template: template(),
  
  
  components: {
    AppLogo,
    SessionMenu,
    PhoneNumber,
    MunicipalitySelector
  },

  mixins: [ContactMixin],

  data() {
    return {
      default: DEFAULT,
      socialMediaItems: SOCIAL_MEDIA,
      modalAccessibility:false,
      modoContraste:false,
      srcicono:""
      
    }
  },

  computed: {
    ...mapGetters(['currentAppConfig', 'currentMunicipality',
      "contraste"]),

    appStoreLinks() {
      const appStoreLinks = []

      if (this.currentAppConfig.google_play_url) {
        appStoreLinks.push({
          icon: 'android',
          link: this.currentAppConfig.google_play_url
        })
      }

      if (this.currentAppConfig.apple_store_url) {
        appStoreLinks.push({
          icon: 'ios',
          link: this.currentAppConfig.apple_store_url
        })
      }

      return appStoreLinks
    },
    socialMediaItemsByArea() {
      let socialMediaItemsAux = []
      let auxSocialMedia = null
      if (this.currentMunicipality && this.currentMunicipality.code &&
        Number.isInteger(Number(this.currentMunicipality.code))) {
        auxSocialMedia = this.socialMediaItems
          .filter(socialMedia => socialMedia.scope ===
            Number(this.currentMunicipality.code))
        if (auxSocialMedia && auxSocialMedia.length > 0)socialMediaItemsAux = auxSocialMedia[0]
      } else {
        auxSocialMedia = this.socialMediaItems
          .filter(socialMedia => socialMedia.scope === this.default)
        if (auxSocialMedia && auxSocialMedia.length > 0)socialMediaItemsAux = auxSocialMedia[0]
      }
      if (!auxSocialMedia) {
        socialMediaItemsAux = this.socialMediaItems
      }

      return socialMediaItemsAux
    },
    
  },
  mounted() {
    $("body").removeClass("ns-sidepanel--overlay-active");
    var iconoa = document.getElementById("iconoacce");
    iconoa.src = IconoAccesibility;
  },
  created(){
    var iconoa = document.getElementById("iconoacce");
    if(iconoa) iconoa.src = IconoAccesibility;
  },
 
 
  updated(){
    
   
    if(this.contraste){
      // console.log(`Applying high contrast for component ${this.$options.name}`)
      var logoSuperior = document.getElementById("logoSuperior");
      var logoFooter = document.getElementById("logo_footer");
      logoSuperior.src = LogoBlack;
      logoFooter.src = LogoBlack;
      
      
      $(".ns-layout ,.ns-layout__header, .text-black , .text-gray2 , .sidepanel-map__card--item__stop--route , .sidepanel-map__card--item__stop,.flex-middle").css("cssText" ," color: #ffffff !important")
      $(".ns-layout ,.ns-layout__header, .text-black , .text-gray2 , .sidepanel-map__card--item__stop--route , .sidepanel-map__card--item__stop").css("background-color" ,"#1e1e1e")
      $(".text-primary , .text-gray2-hover , .button-phone , .text-gray3 , .ns-layout__sidebar__map__menu").css("cssText" ," color: #ffffff !important")
      $(".text-primary , .text-gray2-hover , .button-phone , .text-gray3 , .ns-layout__sidebar__map__menu").css("background-color" ,"#1e1e1e")
      $("a ,.sidepanel-map__container, .recharge-home__recharges , .recharge-home__cities , .ns-layout__footer__items , .pb-d6 , .sidepanel-map__card--item__point-name").css("color" ,"#fff")
      $(".sidepanel-map__container, .recharge-home__recharges , .recharge-home__cities , .ns-layout__footer__items ,.pb-d6, .sidepanel-map__card--item__point-name,.flex-middle ,.sidepanel-map__header").css( "cssText" ,"background-color: #1e1e1e !important")
      $(".sidepanel-map--planner__form , .uppercase-vz ,.hidden-xs ,.panel,.ns-field__label,.uppercase ,#fares-side-menu,.flex-column").css("color" ,"#fff")
      $(".sidepanel-map--planner__form , .uppercase-vz ,.hidden-xs ,.panel,.ns-field__label,.uppercase ,#fares-side-menu,.flex-column").css("background-color" ,"#1e1e1e")
      $("label,.ns-tabs__item,.primay-dark,.text-small, .ns-icon-angle-right ,.h1-main-title,.text-main-title ").css("color" ,"#fff")
      $("label,.ns-tabs__item,.primay-dark,.text-small, .ns-icon-angle-right ,.h1-main-title,.text-main-title ").css("background-color" ,"#1e1e1e")
      $(".ns-layout__header--city , .ns-button,.text-info , .ns-icon-youtube").css("color" ,"#1e1e1e");
      $(".ns-layout__header--city , .ns-button,.text-info , .ns-icon-youtube").css("background-color" ,"#fff")
      $(" .pb-d6 ").css("color" ,"#fff")  
      $("#iconorestablecer").css("color" ,"#1e1e1e");
      $("#iconorestablecer").css("background-color" ,"#fff")
      $("#textorestablecer").css("color" ,"#1e1e1e");
      $("#textorestablecer").css("background-color" ,"#fff")
      $(".text-gray2,.text-primary-hover ,.ns-layout__footer ,.ns-layout__footer__footer,.hidden-cl ").css("cssText" ," color: #ffffff !important")
      $(".panel-schedules ,.px-d2 , .ns-session__nav--login ,.ns-layout__footer__footer").css( "cssText" ,"background-color: #1e1e1e !important")
      $(".tablacss ").css( "cssText" ,"color: #1e1e1e !important")
        $(".tablacss ").css("background-color" ,"#fff")
        $(".ns-icon-angle-left ").css("color" ,"#fff")
        $(".ns-icon-angle-left ").css("background-color" ,"#1e1e1e")
        $("#linkhome ").css( "cssText" ,"color: #1e1e1e !important")
        $(".ns-layout__sidebar__map__menu").css("background-color" ,"#ffffff")
        $(".textomenu ").css( "cssText" ,"color: #1e1e1e !important")

        $(".submenuusuario").css("background-color" ,"#ffffff")
        $(".submenuusuario").css( "cssText" ,"color: #1e1e1e !important")
     
      &(".button-phone").css("border", "2px solid #ffffff")

    }else{
      
     
      $(".ns-layout ,.ns-layout__header, .text-black , .text-gray2 , .sidepanel-map__card--item__stop--route , .sidepanel-map__card--item__stop").css("color" ,"")
      $(".ns-layout ,.ns-layout__header, .text-black , .text-gray2 , .sidepanel-map__card--item__stop--route , .sidepanel-map__card--item__stop").css("background-color" ,"")
      $(".text-primary , .text-gray2-hover , .button-phone , .text-gray3 , .ns-layout__sidebar__map__menu").css("color" ,"")
      $(".text-primary , .text-gray2-hover , .button-phone , .text-gray3 , .ns-layout__sidebar__map__menu").css("background-color" ,"")
      $("a ,.sidepanel-map__container, .recharge-home__recharges , .recharge-home__cities , .ns-layout__footer__items , .pb-d6 , .sidepanel-map__card--item__point-name").css("color" ,"")
      $(".sidepanel-map__container, .recharge-home__recharges , .recharge-home__cities , .ns-layout__footer__items ,.pb-d6, .sidepanel-map__card--item__point-name,.flex-middle ,.sidepanel-map__header").css( "background-color" ,"")
      $(".sidepanel-map--planner__form , .uppercase-vz ,.hidden-xs ,.panel,.ns-field__label,.uppercase ,#fares-side-menu,.flex-column").css("color" ,"")
      $(".sidepanel-map--planner__form , .uppercase-vz ,.hidden-xs ,.panel,.ns-field__label,.uppercase ,#fares-side-menu,.flex-column").css("background-color" ,"")
      $("label,.ns-tabs__item,.primay-dark,.text-small, .ns-icon-angle-right ,.h1-main-title,.text-main-title ").css("color" ,"")
      $("label,.ns-tabs__item,.primay-dark,.text-small, .ns-icon-angle-right ,.h1-main-title,.text-main-title,.ns-session__nav--login ").css("background-color" ,"")
      $(".ns-layout__header--city , .ns-button,.text-info , .ns-icon-youtube,.ns-tabs, .button-tabs,.text-primary-hover,.ns-layout__footer__footer,.hidden-cl").css("color" ,"");
      $(".ns-layout__header--city , .ns-button,.text-info , .ns-icon-youtube ,.mb-d1-2 ,.px-d2 ,.ns-tabs__item ,.panel-schedules ,.ns-tabs, .button-tabs,.ns-layout__footer__footer,.hidden-cl").css("background-color" ,"")
      $(" .pb-d6 ,.panel-schedules ,.px-d2 ").css("color" ,"")  
      $("#iconorestablecer").css("color" ,"#1e1e1e");
      $("#iconorestablecer").css("background-color" ,"#fff")
      $("#textorestablecer").css("color" ,"#1e1e1e");
      $("#textorestablecer").css("background-color" ,"#fff")
      $("#linkhome").css( "color" ,"")
      $(".textomenu").css( "color","")
      $(".tablacss ").css( "color","")
      $(".tablacss ").css("background-color" ,"")
      $(".ns-icon-angle-left ").css("color" ,"")
      $(".ns-icon-angle-left ,.colorFondo  ").css("background-color" ,"")
      
    }
  },
  methods: {
    // ...mapActions(['openContraste','closeContraste']),
    ...mapActions(['closeContraste', 'showAccessibilityModal', 'openLanguageModal']),
   
    login() {
      this.$emit('login')
    },

    logout() {
      this.$emit('logout')
    },

    closeMenu() {
      this.$emit('close-menu')
    },

    // changeLanguage() {
    //   this.$emit('change-language')
    // },
    // changeAccesibility() {
    //   var iconocontraste = document.getElementById("iconoContraste");
    //   iconocontraste.src = IconoContraste;
      
    //   this.modoContraste=true
    //   if(this.modalAccessibility== true){
    //     this.modalAccessibility= false
    //   }else{
    //     this.modalAccessibility= true
    //     $("#modal-accesibilidad").css('display','flex');
    //     $("body").addClass("ns-sidepanel--overlay-active");
    //   } 
    // },
    // close() {
    //   $("#modal-accesibilidad").css('display','none');
    //   this.modalAccessibility= false
    //   $("body").removeClass("ns-sidepanel--overlay-active");
      
    // },
    cambiarFuente(valor){  
      var el = document.querySelector("html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video ")
      var fontSize2 = window.getComputedStyle(el,null).getPropertyValue('font-size')
      var rem = (fontSize2.split("px")[0] / 16)
      
      if(valor =="disminuir"){
        if(rem >= 0.9)
        rem = rem  - 0.1
      }else{
        if(rem <= 1.2 ){
          rem = rem +0.1
        }
      }
      el.style.fontSize = rem+"rem"
    },

    // @todo this logic should have been moved to the accessibility modal
    restablecer(){
      this.closeContraste()
      
      var el = document.querySelector("html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video ")
      el.style.fontSize = "1rem"
    },

    applyContrasteCss(){

      // console.log(`applying high contrast for component ${this.$options.name} (${this.contraste})`)

      // @todo refactor logo selectors to refs and make Icons computed
      var logoSuperior = document.getElementById("logoSuperior");
      var logoFooter = document.getElementById("logo_footer");
      var iconoa = document.getElementById("iconoacce");
      var libroReclamo = document.getElementById("complaintsBook");
      
      if(this.contraste){
        if(logoSuperior) logoSuperior.src = LogoBlack;
        if(logoFooter) logoFooter.src = LogoBlack;
        if(iconoa) iconoa.src = IconoAccesibilityBlanco;
        if(libroReclamo) libroReclamo.src = libroReclamoBlanco;
        
        $(".ns-layout ,.ns-layout__header, .text-black , .text-gray2 , .sidepanel-map__card--item__stop--route , .sidepanel-map__card--item__stop ,.flex-middle,.ns-layout__footer__footer").css("cssText" ," color: #ffffff !important")
        $(".ns-layout ,.ns-layout__header, .text-black , .text-gray2 , .sidepanel-map__card--item__stop--route , .sidepanel-map__card--item__stop").css("background-color" ,"#1e1e1e")
        $(".text-primary , .text-gray2-hover , .button-phone , .text-gray3 , .ns-layout__sidebar__map__menu").css("cssText" ," color: #ffffff !important")
        $(".text-primary , .text-gray2-hover , .button-phone , .text-gray3 ").css("background-color" ,"#1e1e1e")
        $("a ,.sidepanel-map__container, .recharge-home__recharges , .recharge-home__cities , .ns-layout__footer__items , .pb-d6 , .sidepanel-map__card--item__point-name").css("color" ,"#fff")
        $(".sidepanel-map__container, .recharge-home__recharges , .recharge-home__cities , .ns-layout__footer__items ,.pb-d6, .sidepanel-map__card--item__point-name,.flex-middle ,.sidepanel-map__header").css( "cssText" ,"background-color: #1e1e1e !important")
        $(".sidepanel-map--planner__form , .uppercase-vz ,.hidden-xs ,.panel,.ns-field__label,.uppercase ,#fares-side-menu,.flex-column").css("color" ,"#fff")
        $(".sidepanel-map--planner__form , .uppercase-vz ,.hidden-xs ,.panel,.ns-field__label,.uppercase ,#fares-side-menu,.flex-column").css("background-color" ,"#1e1e1e")
        $("label,.ns-tabs__item,.primay-dark,.text-small, .ns-icon-angle-right ,.h1-main-title,.text-main-title ").css("color" ,"#fff")
        $("label,.ns-tabs__item,.primay-dark,.text-small, .ns-icon-angle-right ,.h1-main-title,.text-main-title,.colorFondo ").css("background-color" ,"#1e1e1e")
        $(".ns-layout__header--city , .ns-button,.text-info , .ns-icon-youtube").css("cssText" ,"color: #1e1e1e !important");
        $(".ns-layout__header--city , .ns-button,.text-info , .ns-icon-youtube").css("background-color" ,"#fff")
        $(" .pb-d6 ").css("color" ,"#fff")  
        $("#iconorestablecer").css("color" ,"#1e1e1e");
        $("#iconorestablecer").css("background-color" ,"#fff")
        $("#textorestablecer").css("color" ,"#1e1e1e");
        $("#textorestablecer").css("background-color" ,"#fff")
        $(".text-gray2,.text-primary-hover ,.ns-layout__footer, .ns-layout__footer__footer,.hidden-cl ").css("cssText" ," color: #ffffff !important")
        $(".panel-schedules ,.px-d2 , .ns-session__nav--login ,.ns-layout__footer__footer").css( "cssText" ,"background-color: #1e1e1e !important")
        $(".ns-tabs ").css("background-color" ,"#1e1e1e")
        $("#linkhome ").css( "cssText" ,"color: #1e1e1e !important")
       
        $(".tablacss ").css( "cssText" ,"color: #1e1e1e !important")
        $(".tablacss ").css("background-color" ,"#fff")
        $(".ns-icon-angle-left ").css("color" ,"#fff")
        $(".ns-icon-angle-left ").css("background-color" ,"#1e1e1e")
        $("#linkhome ").css( "cssText" ,"color: #1e1e1e !important")
        $(".ns-layout__sidebar__map__menu").css("background-color" ,"#ffffff")
        $(".textomenu ").css( "cssText" ,"color: #1e1e1e !important")

        $(".submenuusuario").css("background-color" ,"#ffffff")
        $(".submenuusuario").css( "cssText" ,"color: #1e1e1e !important")
        $("#tarifas ").css("background-color" ,"#1e1e1e")
        
        $(".button-phone").css("border" ,"2px solid #ffffff")
          
        $( ".footer-link " )
          .mouseover(() => {
            $( this ).css("cssText","color: #1e1e1e !important").css("background-color","white");
          })
          .mouseout(() => {
            $( this ).css("cssText","color: white").css("background-color",""); 
          });

        $( ".option-menu" )
          .mouseover(() => {
            $( this ).css("background-color","transparent").css("border"," 2px solid #1e1e1e");
          })
          .mouseout(() => {
            $( this ).css("background-color","transparent").css("border", "none"); 
            $( ".active" ).css("background-color","transparent").css("border","2px solid #1e1e1e");
          })
        //$( ".option-menu" ).css("border", "none")
        $( ".active" ).css("background-color","transparent").css("border","2px solid #1e1e1e");
      }else{
        $(".ns-layout ,.ns-layout__header, .text-black , .text-gray2 , .sidepanel-map__card--item__stop--route , .sidepanel-map__card--item__stop").css("color" ,"")
        $(".ns-layout ,.ns-layout__header, .text-black , .text-gray2 , .sidepanel-map__card--item__stop--route , .sidepanel-map__card--item__stop").css("background-color" ,"")
        $(".text-primary , .text-gray2-hover , .button-phone , .text-gray3 , .ns-layout__sidebar__map__menu").css("color" ,"")
        $(".text-primary , .text-gray2-hover , .button-phone , .text-gray3 , .ns-layout__sidebar__map__menu").css("background-color" ,"")
        $("a ,.sidepanel-map__container, .recharge-home__recharges , .recharge-home__cities , .ns-layout__footer__items , .pb-d6 , .sidepanel-map__card--item__point-name").css("color" ,"")
        $(".sidepanel-map__container, .recharge-home__recharges , .recharge-home__cities , .ns-layout__footer__items ,.pb-d6, .sidepanel-map__card--item__point-name,.flex-middle ,.sidepanel-map__header").css( "background-color" ,"")
        $(".sidepanel-map--planner__form , .uppercase-vz ,.hidden-xs ,.panel,.ns-field__label,.uppercase ,#fares-side-menu,.flex-column").css("color" ,"")
        $(".sidepanel-map--planner__form , .uppercase-vz ,.hidden-xs ,.panel,.ns-field__label,.uppercase ,#fares-side-menu,.flex-column").css("background-color" ,"")
        $("label,.ns-tabs__item,.primay-dark,.text-small, .ns-icon-angle-right ,.h1-main-title,.text-main-title ").css("color" ,"")
        $("label,.ns-tabs__item,.primay-dark,.text-small, .ns-icon-angle-right ,.h1-main-title,.text-main-title,.ns-session__nav--login ").css("background-color" ,"")
        $(".ns-layout__header--city , .ns-button,.text-info , .ns-icon-youtube,.ns-tabs, .button-tabs,.text-primary-hover,.ns-layout__footer__footer,.hidden-cl").css("color" ,"");
        $(".ns-layout__header--city , .ns-button,.text-info , .ns-icon-youtube ,.mb-d1-2 ,.px-d2 ,.ns-tabs__item ,.panel-schedules ,.ns-tabs, .button-tabs,.ns-layout__footer__footer,.hidden-cl").css("background-color" ,"")
        $(" .pb-d6 ,.panel-schedules ,.px-d2 ").css("color" ,"")  
        $("#iconorestablecer").css("color" ,"#1e1e1e");
        $("#iconorestablecer").css("background-color" ,"#fff")
        $("#textorestablecer").css("color" ,"#1e1e1e");
        $("#textorestablecer").css("background-color" ,"#fff")
        $("#linkhome").css( "color" ,"")
        $(".textomenu").css( "color","")
        $(".tablacss ").css( "color","")
        $(".tablacss ").css("background-color" ,"")
        $(".ns-icon-angle-left ").css("color" ,"")
        $(".ns-icon-angle-left ,.colorFondo  ").css("background-color" ,"")
        $("#tarifas ").css("background-color" ,"")
        
        $(".button-phone").css("border" ,"none")
          
        $( ".footer-link " )
          .mouseover(() => {
            $( this ).css("cssText","color: ").css("background-color","");
          })
          .mouseout(() => {
            $( this ).css("cssText","color: ").css("background-color",""); 
          });

        $( ".option-menu" )
          .mouseover(() => {
            $( this ).css("background-color","").css("border"," none");
          })
          .mouseout(() => {
            $( this ).css("background-color","").css("border", "none"); 
            $( ".active" ).css("background-color","").css("border","none");
          })
        //$( ".option-menu" ).css("border", "none")
        $( ".active" ).css("background-color","transparent").css("border","2px solid #1e1e1e");
        if(logoSuperior) logoSuperior.src = LogoOriginal;
        if(logoFooter) logoFooter.src = LogoOriginal;
        if(iconoa) iconoa.src = IconoAccesibility;
      }
    }
  }
})
