Vue.filter('selectRouteName', route => {
  if (route) {
    const name = route.name
    const origin = route.origin ? ` | ${route.origin}` : ''
    const destination = route.destination ? ` - ${route.destination}` : ''

    return `${name}${origin}${destination}`
  }
  return ''
})
