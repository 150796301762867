/*
 * Método que construye un objeto de mensajes para vue-i18n. Recibe como parámetros:
 *  - context: require.context con los ficheros de idiomas
 *  - filename: fichero a cargar incluido dentro del context
 *  - availableLocales: array de idiomas disponibles a cargar. Ej: ['es', 'en']
 *  - messages: objeto de mensajes de idiomas ya cargados en la aplicación
 */

export default function buildMessageObject(context, filename, availableLocales, messages) {
  const regexp = new RegExp(`([${availableLocales.join('|')}]+)\\.`, 'i')
  const matched = filename.match(regexp)

  if (matched && matched.length > 1) {
    const locale = matched[1] // Idioma del fichero
    messages[locale] = _.merge(messages[locale] || {}, context(filename))
  }
}
