const NotificationsPlugin = {
  install(Vue) {
    Vue.notifications = {
      send(options) {
        const notification = {
          duration: 10000,
          close: false
        }

        Object.assign(notification, options)

        const existantNotification = _.findIndex(
          Vue.store.state.notifications, item => item.id === notification.id
        )

        if (existantNotification === -1) {
          Vue.store.commit('ADD_NOTIFICATION', notification)
        }
      }
    }

    Vue.prototype.$notifications = Vue.notifications
  }
}

Vue.use(NotificationsPlugin)
