const TransportRequest = () => import('js/pages/transport-request/index.js')
const GeneralConditionsOfTransport = () => import('js/pages/general-conditions-of-transport/index.js')
const InstitutionalInformation = () => import('js/pages/institutional-information/index.js')
const cookiesPolicy = () => import('js/pages/cookies-policy-text/index.js')
const PressNews = () => import('js/pages/press-news/index.js')
const privacyPolicyText = () => import('js/pages/privacy-policy-text/index.js')
const AccessibilityStatement = () => import('js/pages/accessibility-statement/index.js')

export default [
  {
    path: '/transport-request',
    name: 'transport-request',
    component: TransportRequest,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/general-conditions-of-transport',
    name: 'general-conditions-of-transport',
    component: GeneralConditionsOfTransport,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/institutional-information',
    name: 'institutional-information',
    component: InstitutionalInformation,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/cookies-policy-text',
    name: 'cookies-policy-text',
    component: cookiesPolicy,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/press-news',
    name: 'press-news',
    component: PressNews,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/privacy-policy-text',
    name: 'privacy-policy-text',
    component: privacyPolicyText,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/accessibility-statement',
    name: 'accessibility-statement',
    component: AccessibilityStatement,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  }
]