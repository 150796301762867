import customaccessibilityTabs from "../../../../config/before-mount/accesibility-tab-navigation"
import _ from 'lodash'

export default ({
  
  state: {
    /** Flag which indicates if the accessibility options are activated in the client
     * @todo Add the logic to backend before passing to pro
     */
    accessibility_active: false,

    // KEYBOARD NAVEGATION
    loaded: false,
    registeredComponents: {},

    // HIGH CONTRAST OPTIONS
    contraste: false,

    // TEXT TO SPEECH OPTIONS
    textoVoz: false,

    // TEXT SIZE OPTIONS
    fontSize: 16
  },
  
  mutations: {
    registerComponent(state, name) {
      state.registeredComponents[name] = false
    },
    resetMountedComponents(state) {
      state.registeredComponents = {}
    },
    setComponentMounted(state, name) {
      state.registeredComponents[name] = true
    },
    changePageLoaded(state, newState){
      state.loaded = newState
    },

    // HIGH CONTRAST
    SET_CONTRASTE(state, value) {
      state.contraste = value
      localStorage.setItem('highContrast', value)
    },

    // TEXT TO SPEECH
    SET_TEXTOVOZ(state, value) {
      state.textoVoz = value
      localStorage.setItem('textToSpeech', value)
    },

    // FONT SIZE
    setFontSize(state, value){
      state.fontSize = value
    }
  },
  
  getters: {
    /**
     * The active flag decides if the accesibility options should be activated or not
     * Until the work is complete it should be set to false when outside of the development environment
     */
    getAccesibilityActive(state){
      return state.accessibility_active
    },

    /**
     * Checks if all registered components are mounted
     * @returns Boolean
     */
    allComponentsMounted(state) {
      return !Object.values(state.registeredComponents).includes(false)
    },
    getPageLoadedState(state){
      return state.loaded
    },
    getHelperVideoUrl(state){
      return state.helperVideoUrl
    },

    // HIGH CONTRAST
    contraste: state => state.contraste,

    // TEXT TO SPEECH
    textoVoz: state => state.textoVoz
  },

  actions: {

    // KEYBOARD NAVEGATION

    /**
     * Marks the name as mounted using the mutation then calls @checkAccessibilityTabs
     * @param {String} name 
     */
    componentMounted({commit, dispatch}, name){
      commit('setComponentMounted', name)
      dispatch('checkAccessibilityTabs', name)
    },

    /**
     * Checks if all registered components are mounted and call the setup method
     * The component name is passed to the setup method allowing us to identify the last component rendered (debug info)
     * @param {String} component_name 
     */
    checkAccessibilityTabs({getters, commit}, component_name){
      /** Checks that all components are mounted AND that the page is not already loaded preventing modals from executing the script again */
      if(getters.allComponentsMounted && !getters.getPageLoadedState) {
        debouncedMethod({commit}, component_name)
      }
    },

    /**
     * Resets the states allowing the logic to execute on the next page load
     * This action should be called before loading new pages (router navegation)
     */
    resetAccessibilityTabs({commit}){
      commit('resetMountedComponents')
      commit('changePageLoaded', false)
    },

    resetAccessibilityOptions({dispatch}){
      dispatch('closeContraste')
      dispatch('closeTextoVoz')
      dispatch('resetFontSize')
    },

    // HIGH CONTRAST

    initializeAccessibilityOptions({dispatch}) {
      // console.log("Initializing accessibility options")
      dispatch('initializeHighContrast')
      dispatch('initializeTextSize')
      dispatch('initializeTextToSpeech')
    },

    openContraste({ commit, dispatch }) {
      commit('SET_CONTRASTE', true)
      dispatch('highContrast')
    },

    closeContraste({ commit, dispatch }) {
      commit('SET_CONTRASTE', false)
      dispatch('highContrast')
    },

    toggleHighContrast({getters, dispatch}) {
      // console.log("")
      // console.log(`TOGGLING HIGH CONTRAST. CURRENT STATE IS ${getters.contraste}`)
      if(getters.contraste) {
        // console.log("DEACTIVATING")
        dispatch('closeContraste')
      } else {
        // console.log("ACTIVATING")
        dispatch('openContraste')
      }
    },

    highContrast({state, dispatch})
    {
      // @todo refactor and centralize the funcionality of high contrast WIP
      if(state.contraste) {
      $('body').addClass('highContrast')
      } else {
      $('body').removeClass('highContrast')
      }
      // dispatch('activateHighContrast')
    },

    activateHighContrast({ state }) {
      
      // if(state.contraste) {
      //   $('div').css('background-color', '#1e1e1e')
      //   $('div').css('color', '#ffffff')
      // }

/*
      // Rule1
      var colorFFFFFF = [
        ".ns-layout",
        ".ns-layout__header",
        ".text-black",
        ".sidepanel-map__card--item__stop--route",
        ".sidepanel-map__card--item__stop",
        ".flex-middle",
        ".text-primary",
        ".text-gray2-hover",
        ".button-phone",
        ".text-gray3",
        ".ns-layout__sidebar__map__menu",
        ".pb-d6",
        "#perfil",
        "#submenu",
        ".text-gray2",
        ".text-primary-hover",
        ".ns-layout__footer",
        ".ns-layout__footer__footer",
        ".hidden-cl",
        "td",
        "#sublogout",
        "#account-balance",
        "#account-rut-passport",
        ".ns-icon-qr",
        ".h3-main-title",
        ".ns-icon-coins",
        ".ns-icon-wallet",
        ".ns-icon-ticket"
      ]
      $(colorFFFFFF.join(',')).css("cssText", !state.contraste ? "color: " : "color: #ffffff !important")

      // Rule2
      var BGC1e1e1e =  [
        ".uppercase-vz",
        ".hidden-xs",
        ".panel",
        ".ns-field__label",
        ".uppercase",
        "body",
        ".ns-layout",
        ".ns-layout__header",
        ".text-black",
        ".text-gray2",
        ".sidepanel-map__card--item__stop--route",
        ".sidepanel-map__card--item__stop",
        ".text-primary",
        ".text-gray2-hover",
        ".button-phone",
        ".text-gray3",
        ".sidepanel-map--planner__form",
        "#fares-side-menu",
        ".flex-column",
        "label",
        ".ns-tabs__item",
        ".primay-dark",
        ".text-small",
        ".ns-icon-angle-right",
        ".h1-main-title",
        ".text-main-title",
        ".colorFondo",
        ".ns-tabs",
        ".ns-icon-angle-left",
        "#iconoacce",
        "#sublogout",
        ".payment-media-panel",
        "#account-balance",
        "#tarifas"
      ]
      $(BGC1e1e1e.join(',')).css("background-color", !state.contraste ? "" : "#1e1e1e")

      // Rule3
      var colorFFF = [
        "a",
        ".sidepanel-map__container",
        ".recharge-home__recharges",
        ".recharge-home__cities",
        ".ns-layout__footer__items",
        ".pb-d6",
        ".sidepanel-map__card--item__point-name",
        ".sidepanel-map--planner__form",
        ".uppercase-vz",
        ".hidden-xs",
        ".panel",
        ".ns-field__label",
        ".uppercase",
        "#fares-side-menu",
        ".flex-column",
        "label",
        ".ns-tabs__item",
        ".primay-dark",
        ".text-small",
        ".ns-icon-angle-right",
        ".h1-main-title",
        ".text-main-title",
        ".ns-icon-angle-left",
        ".flex-middle",
        "#menuPerfil",
        "#subMenuPerfil",
        "#sublogout",
        ".ns-icon-coins",
        ".sidepanel-map__card--item__stop"
      ]
      $(colorFFF.join(',')).css("color", !state.contraste ? "" : "#fff")

      // Rule4
      var BGC1e1e1eImp = [
        ".sidepanel-map__container",
        ".recharge-home__recharges",
        ".recharge-home__cities",
        ".ns-layout__footer__items",
        ".pb-d6",
        ".sidepanel-map__card--item__point-name",
        ".flex-middle",
        ".sidepanel-map__header",
        ".panel-schedules",
        ".px-d2",
        ".ns-session__nav--login",
        ".ns-layout__footer__footer",
        ".panel",
        "tr",
        ".ns-layout",
        ".ns-layout__header",
        ".text-black",
        ".text-gray2",
        ".sidepanel-map__card--item__stop--route",
        ".sidepanel-map__card--item__stop",
        ".text-primary",
        ".text-gray2-hover",
        ".button-phone",
        ".text-gray3",
        ".ns-layout__sidebar__map__menu",
        ".text-primary-hover",
        ".ns-layout__footer",
        ".hidden-cl",
        "#perfil",
        "#submenu"
      ]
      $(BGC1e1e1eImp.join(',')).css("cssText", "background-color: " + !state.contraste ? "" : "#1e1e1e !important")

      // Rule5
      var color1e1e1eImp = [
        ".ns-layout__header--city",
        ".ns-button",
        ".text-info",
        ".ns-icon-youtube",
        ".text-gray1",
        "#linkhome",
        ".tablacss",
        ".textomenu",
        "#iconoacce",
        ".submenuusuario",
        ".preview",
        ".ns-field",
        ".titulomodal",
        "#TituloAceesibilidad"
      ]
      $(color1e1e1eImp.join(',')).css("cssText", "color: " + !state.contraste ? "" : "#1e1e1e !important")
      
      // Rule6
      var BGCFFF = [
        ".ns-layout__header--city",
        ".ns-button",
        ".text-info",
        ".ns-icon-youtube",
        "#iconorestablecer",
        "#textorestablecer",
        ".tablacss"
      ]
      $(BGCFFF.join(',')).css("background-color", !state.contraste ? "" : "#fff")


      // Rule7
      var COL1e1e1e = [
        "#iconorestablecer",
        "#textorestablecer",
        ".ns-layout__header--city",
        ".ns-button",
        ".text-info",
        ".ns-icon-youtube"
      ]
      $(COL1e1e1e.join(',')).css("color", !state.contraste ? "" : "#1e1e1e");

      
      // Rule8
      var BGCFFFFFF = [
        ".ns-layout__sidebar__map__menu",
        ".submenuusuario",
        ".ns-icon-coins"
      ]
      $(_.uniq(BGCFFFFFF).join(',')).css("background-color", !state.contraste ? "" : "#ffffff")


      $(".button-phone")
        .css("border", state.contraste ? "2px solid #ffffff" : "none")
        .css("border-color", state.contraste ? "#ffffff" : "")

      $(".active")
        .css("background-color", state.contraste ? "transparent" : "")
        .css("border", "2px solid "+ state.contraste ? "" : "#1e1e1e")

      if (!state.contraste) {
        $(".option-menu")
          .mouseover(function () {
            $(this).css("background-color", "transparent").css("border", "2px solid #1e1e1e");
          })
          .mouseout(function () {
            $(this).css("background-color", "transparent").css("border", "none");
            $(".active").css("background-color", "transparent").css("border", "2px solid #1e1e1e");
          })

        $(".footer-link, .boton-acces, .sesion, .idioma, .contacto, .ns-session")
          .mouseover(function () {
            $(this).css("cssText", "#1e1e1e").css("background-color", " #ffffff")
          })
          .mouseout(function () {
            $(this).css("cssText", "color: #ffffff").css("background-color", "")
          })
      } else {
        $(".option-menu")
          .mouseover(function () {
            $(this).css("background-color", "").css("border", "none")
          })
          .mouseout(function () {
            $(this).css("background-color", "").css("border", "none")
            $(".active").css("background-color", "").css("border", " none")
          })


        $(".footer-link, .boton-acces, .sesion, .idioma, .contacto, .ns-session")
          .mouseover(function () {
            $(this).css("cssText", "").css("background-color", "")
          })
          .mouseout(function () {
            $(this).css("cssText", "color: ").css("background-color", "")
          })
      }
          */
    },

    initializeHighContrast({state, dispatch}){
      // console.log("Initializing High Contrast")
      // console.log("Client accessibility flag: ", state.accessibility_active)
      if (!state.accessibility_active) {
        dispatch('closeContraste')
        return
      }
      let storedValue = localStorage.getItem('highContrast') === "true"
      if (typeof storedValue !== "undefined") {
        if(storedValue){
          dispatch('openContraste')
        } else {
          dispatch('closeContraste')
        }
      } else {
        dispatch('closeContraste')
      }
    },

    // TEXT TO SPEECH

    openTextoVoz({commit} ) {
      commit('SET_TEXTOVOZ', true)
      localStorage.setItem('textToSpeech', true)
    },

    closeTextoVoz({commit}) {
      commit('SET_TEXTOVOZ', false)
      localStorage.setItem('textToSpeech', false)
    },

    toggleTextToSpeech({commit, state}){
      // console.log('Setting textToVoice to', !state.textoVoz)
      commit('SET_TEXTOVOZ', !state.textoVoz)
      localStorage.setItem('textToSpeech', state.textoVoz)
    },

    /**
     * A phrase to be spoken. Multiple phrases can be passed in an array. 
     * null values will be ignored allowing logic to conditionally chain phrases.
     * @param {String|Array} data 
     */
    escucharAudio({state}, data){
      speechSynthesis.cancel();
      data = Array.isArray(data) ? data : [data]
      let language = getStoredLanguage()
      if(state.textoVoz){
        data.forEach(async function(phrase) {
          if(_.isEmpty(phrase)) return
          await speak(phrase, language)
        })
      }
    },

    initializeTextToSpeech({state, dispatch}) {
      // console.log('Initializing Text to speech')
      if (!state.accessibility_active) {
        dispatch('closeTextoVoz')
        return
      }
      let storedValue = localStorage.getItem('textToSpeech') === "true"
      if (typeof storedValue !== "undefined") {
        if(storedValue){
          dispatch('openTextoVoz')
        } else {
          dispatch('closeTextoVoz')
        }
      } else {
        dispatch('closeTextoVoz')
      }
    },

    // FONT SIZE
    
    resetFontSize({commit}){
      var el = document.querySelector("html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video ")
      el.style.fontSize = "1rem"
      commit('setFontSize')
      localStorage.setItem("fontSize", 16);
    },

    cambiarFuente({commit, state}, valor) {
      var el = document.querySelector(
        "html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video "
      );
      var fontSize2 =
        localStorage.getItem("fontSize") ? localStorage.getItem("fontSize") : 16
      var rem = fontSize2 / 16;

      switch (valor) {
        case "disminuir":
          if (rem >= 0.9) rem -= 0.1;
          break;
        case "aumentar":
          if (rem <= 1.2) rem += 0.1;
          break;
        default:
          break;
      }

      el.style.fontSize = rem + "rem";

      commit("setFontSize", Math.round(rem * 16));
      localStorage.setItem("fontSize", Math.round(rem * 16));
    },

    initializeTextSize({state, dispatch}){
      // console.log('Initializing Text size')
      if (!state.accessibility_active) {
        dispatch('resetFontSize')
        return
      }
      let storedValue = localStorage.getItem('fontSize')
      if (typeof storedValue !== "undefined") {
        dispatch('cambiarFuente')
      } else {
        dispatch('resetFontSize')
      }
    }
  }
})

// KEYBOARD NAVEGATION

/**
 * This debounced method ensures that only when the last component is ready will the tabs be generated
 * I've added a flag to prevent this behaviour executing on modal loads.
 */
const debouncedMethod = _.debounce(function(context, name='vuex'){
  customaccessibilityTabs.setup(name)
  context.commit('changePageLoaded', true)
}, 2000, { 'leading': false, 'trailing': true })


// TEXT TO SPEECH

async function speak(text, lang='en-EN') {
  // console.log("saying", text, lang)
  let phrase = new SpeechSynthesisUtterance()
  phrase.text = text
  phrase.lang = lang
  speechSynthesis.speak(phrase)

  return new Promise(resolve => {
    speechSynthesis.onend = resolve;
  });
}

function getStoredLanguage() {
  switch(localStorage.getItem('app-language')) {
    case "en":
      return 'en-US'
    case "pt":
      return 'pt-PT'
    case "fr":
      return 'fr-FR'
    case "de":
      return 'de-DE'
    case "va":
      return 'va-VA'
    default:
      return "es-ES"
  } 
}