import Vue from "vue"
import { mapGetters, mapActions } from "vuex"

import IconoAccesibilityBlanco from "assets/images/iconoaccessibilityblanco.svg"
import IconoAccesibility from 'assets/images/iconoaccesibility.svg'
import IconoContraste from 'assets/images/iconoContraste.svg'

import textToSpeechActive from 'assets/images/ico-voz-azul.svg'
import textToSpeechInactive from 'assets/images/ico-voz.svg'
import YouTubeIcon from 'assets/images/standard-icons/svg/youtube_alt.svg'

import template from "./index.pug"

export default Vue.extend({
  name: 'accessibility',
  template: template(),

  computed: {
    ...mapGetters(["contraste", "textoVoz"]),
    
    getVoiceMessageHighContrast(){
      return this.contrast 
        ? this.$t('accessibility.helperVoiceHighContrastOff')
        : this.$t('accessibility.helperVoiceHighContrastOn')
    },

    getHighContrastIcon(){
      return IconoContraste
    },
    
    getTextToSpeech(){
      return this.contrast 
        ? this.$t('accessibility.activateTextoVoz')
        : this.$t('accessibility.deactivateTextoVoz')
    },

    getTextToSpeechIcon(){
      return this.textoVoz ? textToSpeechActive : textToSpeechInactive
    },
    
    /**
     * Used for the helper video icon
     */
    getYoutubeIcon(){
      return YouTubeIcon
    }
  },
  
  methods: {
    ...mapActions([
      'toggleHighContrast', 
      'toggleTextToSpeech',
      "showHelperVideo",
      "closeAccessibilityModal",
      "resetAccessibilityOptions",
      "cambiarFuente"
    ]),

    openHelperVideo(){
      this.$emit('close')
      this.showHelperVideo()
    },
  },

  mounted(){
    this.$nextTick(() => {
      this.customAccesibilityTabs.setup('vuex-language-modal-vuex-action', "#modal-accesibilidad")
    })
  }
})
