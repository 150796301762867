/**
 * This module duplicates the tab and shift+tab behaviour on the cursor keys
 */

import _ from "lodash"

class accessibilityTabs {

  // Store the currently active tab
  current_accessibility_tab_index = null
  elements_tabindex = []
  listenerCreated = false

  lastFocusedElement = null

  constructor() {
    if (!accessibilityTabs.instance) {
      accessibilityTabs.instance = this
    }
  }

  setup(caller, selector = 'body') {
    const lastFocusedElementTabIndex = this.getLastFocusedElement()?.getAttribute('tabindex');
    // console.log("Last element focused:",this.getLastFocusedElement());
    this.teardown()
    this.updateElementsTabIndex(selector)
    // console.log(`Called from ${caller}`, this.elements_tabindex)
    if (this.elements_tabindex.length > 0) {
      for (const el of this.elements_tabindex) {
        el.addEventListener('focus', this.updateCurrentaccessibilityTabIndex.bind(this))
      }
    }
    if (!this.listenerCreated) {
      document.addEventListener('keydown', this.checkPressedKey.bind(this))
      this.listenerCreated = true
    }

    this.selectLastFocusedElement(lastFocusedElementTabIndex)
  }
  //Se me ocurren tres formas de hacerlo:
  // Recorrer elements_tabindex para ver que item tiene focus
  // Recorrer element_tabindex y comparar current_accesibility_tab_index con item.dataset.accesibility
  // Guardar en getNextElement el elemento focused
  getLastFocusedElement() {
    if (this.lastFocusedElement === document.activeElement)
      return this.lastFocusedElement
    return null;
  }
  selectLastFocusedElement(tabindex) {
    if(!tabindex)return;
    const index = this.elements_tabindex.findIndex(el => el.getAttribute("tabindex") === tabindex);
    // console.log("findIndex", index);
    if (index !== -1) {
      this.current_accessibility_tab_index = index;
      this.elements_tabindex[index].focus({ focusVisible: true })
      this.lastFocusedElement = this.elements_tabindex[index]
    }
  }
  teardown() {
    for (const el of this.elements_tabindex) {
      el.dataset.accessibility = null
      el.removeEventListener('focus', this.updateCurrentaccessibilityTabIndex)
    }
    this.elements_tabindex = []
    this.current_accessibility_tab_index = null

  }

  /**
   * 
   * @param {String} selector an element to use as the starting point for DOM selection
   */
  updateElementsTabIndex(selector = 'body') {
    let rootElement = document.querySelector(selector)

    if(_.isEmpty(rootElement)) {
      let tries = 0
      while(tries < 500 && _.isEmpty(rootElement)){
        rootElement = document.querySelector(selector)
        tries++
      }
      if(tries === 500) return
    }

    const elementsToSelect = {
      'indexed': '[tabindex]',
      'links': 'a',
      'form_elements': 'input, select, textarea, button'
    }

    let accessibilityUniqueId = 0

    for (var key in elementsToSelect) {
      switch (key) {
        case 'form_elements':
        case 'links':
          // Al final no vamos a usar elementos que no tienen tabindex 
          // Tras la conversación con Hugo
          break
        default:

          Array.from(rootElement.querySelectorAll(elementsToSelect[key])).forEach((item) => {
            if(_.isEmpty(item)) return
            if (!this.elements_tabindex.includes(item) && item.checkVisibility()) {
              item.dataset.accessibility = accessibilityUniqueId
              this.elements_tabindex.push(item)
              accessibilityUniqueId++
            }

          })

          break
      }
    }
  }

  updateCurrentaccessibilityTabIndex(event) {
    this.current_accessibility_tab_index = parseInt(event.target.dataset.accessibility)
  }

  /**
  * Looks for the next id in the sequence. 
  * If the end is reached it will go back to the first element in the direction required
  * The currently selected item is not update as it will be changed when the new element recieves focus
  * @param {String} direction Only use '>' to progress or '<' to regress
  * @returns {Number} 
  */
  getNextElement(direction) {

    let nextId
    if (this.current_accessibility_tab_index === null) return 0
    // Get the next element from the original array depending on the direction
    if (direction === '>') {
      nextId = this.current_accessibility_tab_index + 1
      nextId = nextId < this.elements_tabindex.length ? nextId : 0
    } else {
      nextId = this.current_accessibility_tab_index - 1
      nextId = (nextId == -1) ? this.elements_tabindex.length - 1 : nextId
    }
    //debugger;
    // console.log("nextId", nextId);
    return nextId
  }

  /**
   * Checks the recieved keystroke and handles the arrows
   * @param {Event} event 
   */
  checkPressedKey(event) {
    let nextId
    //TODO: ver qué event.target es para cambiar el comportamiento
    // console.log("event",event.target);
    switch (event.key) {
      case 'ArrowLeft':
        event.preventDefault()
        nextId = this.getNextElement('<')
        this.focusOnNextElement(nextId)
        break
      case 'ArrowUp':
        
        // event.preventDefault()
        // nextId = this.getNextElement('<')
        // this.focusOnNextElement(nextId)
        break
      case 'ArrowRight':
        event.preventDefault()
        nextId = this.getNextElement('>')
        this.focusOnNextElement(nextId)
        break
      case 'ArrowDown':
        // event.preventDefault()
        // nextId = this.getNextElement('>')
        // this.focusOnNextElement(nextId)
        break
    }
  }

  /**
   * Changes the focus to the provided element in the sequence
   * If duplicate elements are defined the first will be used
   * @param {Number} tabId 
   */
  focusOnNextElement(tabId) {
    let next = document.querySelector(`[data-accessibility="${tabId}"]`)
    if (!next) return
    try {
      next.focus({ focusVisible: true })
      this.lastFocusedElement = next
    } catch (e) {
      console.log('---------------------------------------------------------------------')
      console.log(tabId)
      console.log(e)
      console.log('-----------------')
      console.log(next, typeof next)
      console.log(this.getMethods(next))
      console.log('---------------------------------------------------------------------')
    }
  }

  getMethods(obj) {
    var res = [];
    for (var m in obj) {
      if (typeof obj[m] == "function") {
        res.push(m)
      }
    }
    return res;
  }
}

const customaccessibilityTabs = new accessibilityTabs

export default customaccessibilityTabs