import Vue from 'vue'
import template from './index.pug'

// @vue/component
Vue.component('media-panel', {
  name: 'media-panel',
  template: template(),

  props: {
    text: {
      type: String,
      default: ''
    },

    open: {
      type: Boolean,
      default: false
    },

    toggleable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      localOpen: false
    }
  },

  watch: {
    open(newOpen) {
      if (newOpen !== this.localOpen) {
        this.localOpen = newOpen
      }
    }
  },

  created() {
    this.localOpen = this.open
  },

  methods: {
    toggleOpen() {
      this.localOpen = !this.localOpen
      this.$emit('toggle', this.localOpen)

      if (this.localOpen) {
        this.$emit('open')
        this.scrollTo()
      } else {
        this.$emit('close')
      }
    },

    scrollTo() {
      // Barra de navegación
      const nav = document.getElementsByClassName('ns-layout__sidebar__map__menu')[0]
      // Espacio por encima de donde nos movemos
      const spaceTop = 8
      // Cálculo de la distancia a recorrer
      const scrollTo = this.$refs.movements.offsetTop - (
        nav.offsetHeight + nav.offsetTop + spaceTop)

      setTimeout(() => {
        window.scrollTo({top: scrollTo, behavior: 'smooth'})
      }, 150)
    }
  }
})
