const ErrorPage = () => import('js/pages/error/index.js')

const meta = {
  layoutInfo: true,
  layout: 'default'
}

export default [
  {
    path: '/error/:errorCode',
    name: 'error',
    component: ErrorPage,
    meta
  },
  {
    path: '*',
    redirect: '/'
  }
]
