import {createConsumer} from '@rails/actioncable'

const ActionCablePlugin = {
  install(Vue) {
    Vue.cable = createConsumer(WS_HOST)
    Vue.prototype.$cable = createConsumer(WS_HOST)
  }
}

Vue.use(ActionCablePlugin)
