import MunicipalitiesService from 'js/services/api/topology/municipalities'

// Datos del municipio a utilizar en la aplicación
function municipalityRepresentation(municipality) {
  return {
    ...municipality,
    country_alpha2: municipality.country_alpha2.toLowerCase(),
    distance: undefined // Se calcula a posteriori en la vista home con el propósito de ordenar
  }
}

export default ({
  state: {
    // Listado de municipios disponibles en la plataforma
    availables: [],
    municipality: null
  },

  mutations: {
    setAvailableMunicipalities(state, availables) {
      state.availables = availables
    },

    setMunicipality(state, municipality) {
      state.municipality = municipality
    }
  },

  getters: {
    currentMunicipality(state) {
      return state.municipality
    },

    availableMunicipalities(state) {
      return state.availables
    },

    // Comprueba si se puede seleccionar municipios (si hay más de uno)
    canSelectMunicipality(state) {
      return state.availables.length > 1
    },

    // Comprueba si no se han cargado los municipios
    noMunicipalityLoaded(state) {
      return state.availables.length <= 0
    },

    // Comprueba si no se ha seleccionado un municipios
    noMunicipalitySelected(state) {
      return state.municipality === null || state.municipality === undefined
    },

    // Ruta de página home
    homeRoute(state, getters) {
      if (getters.canSelectMunicipality) {
        return {name: 'home'}
      }
      return {
        name: 'municipality',
        params: {
          country: state.municipality.country_alpha2,
          municipality: state.municipality.name_normalized
        }
      }
    },

    // Primer municipio disponible
    firstAvailableMunicipality(state) {
      return state.availables[0]
    }
  },

  actions: {
    async loadAvailableMunicipalities({commit}) {
      const params = {template: 'with_counters'}
      const response = await MunicipalitiesService.index(params)

      const municipalities = _.map(response.data, municipalityRepresentation)

      commit('setAvailableMunicipalities', municipalities)
    },

    async loadMunicipality({commit}, {municipalityName}) {
      const municipality = await MunicipalitiesService.showSlug(municipalityName)
      commit('setMunicipality', municipality)
    },

    selectMunicipality({commit, state}, {countryAlpha2, municipalityName}) {
      const municipality = state.availables.find(
        mun => mun.country_alpha2 === countryAlpha2.toLowerCase() &&
               mun.name_normalized === municipalityName.toLowerCase()
      )
      commit('setMunicipality', municipality)
    }
  }
})
