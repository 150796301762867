/* eslint-disable no-use-before-define */

const watchPositionOptions = {
  enableHighAccuracy: false,
  timeout: 5000,
  maximumAge: 5000
}

export default {
  watch(onSuccess, onError) {
    referencePositionCallbacks(onSuccess, onError)

    if (navigator.geolocation) {
      return navigator.geolocation.watchPosition(
        onSuccess,
        onError,
        watchPositionOptions
      )
    }

    return null
  },
  clearWatch(watcherId) {
    if (navigator.geolocation) {
      navigator.geolocation.clearWatch(watcherId)
    }
  }
}

/**
 * Crea referencias globales a los callbacks de geolocalización
 * para poder sobrescribir la ubicación en entornos dev/test.
 *
 * La localización se solicita en un momento muy temprano de la
 * aplicación por lo que no es posible mockearla únicamente usando
 * Capybara.
 *
 * TODO: eliminar este método si se consigue una manera de mockear
 * la geolocalización desde capybara sin estas referencias
 */
function referencePositionCallbacks(onSuccess, onError) {
  if (['127.0.0.1', 'localhost'].includes(location.hostname)) {
    window.watchPositionSuccessCallback = onSuccess
    window.watchPositionErrorCallback = onError
  }
}
