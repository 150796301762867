import NoMunicipalityRoutes from 'js/vue-plugins/vue-router/routes/no-municipality'
import CustomNoMunicipalityRoutes from 'js/vue-plugins/vue-router/routes/custom-no-municipality'
import MunicipalityRoutes from 'js/vue-plugins/vue-router/routes/municipalities/index'
import PrivacyPolicyRoutes from 'js/vue-plugins/vue-router/routes/privacy-policy'
import SystemRoutes from 'js/vue-plugins/vue-router/routes/system'

const routes = [].concat(
  NoMunicipalityRoutes,
  CustomNoMunicipalityRoutes,
  MunicipalityRoutes,
  PrivacyPolicyRoutes,
  SystemRoutes
)

export default routes
