import Vue from "vue";
import { mapActions } from "vuex";
import template from "./index.pug";

Vue.component("language-modal", {
  name: 'language-modal',
  template: template(),
  props: {
    accion: "",
  },

  data() {
    return {
      languageValue: undefined,
    };
  },

  computed: {
    availableLanguages() {
      return Vue.language.availableLanguages;
    },
  },

  created() {
    this.languageValue = Vue.language.getLanguage();
  },

  mounted() {
    this.$nextTick(() => {
      this.addDynamicTabIndex();
      $("#languageOptions").focus();
    })
  },

  methods: {

    close() {
      this.$emit("close");
      this.$emit("closeclicked");
    },

    getLanguageSrc(language) {
      return require(`assets/images/language/${language}.png`); // eslint-disable-line
    },

    setSelectedLanguage(language) {
      Vue.language.changeLanguage(language);
      this.close();
      this.$emit("language-changed");
    },

    addDynamicTabIndex() {
      let vm = this
      let options = document.querySelector("#languageOptions");
      if (options) {
        let langs = options.querySelectorAll("label.ns-radio");
        if (langs.length < 1)
          langs = options.querySelectorAll(".ns-radio-custom");
        if (langs.length > 0) {
          langs.forEach((radio, pos) => {
            radio.tabIndex = pos;
            let val = $(radio).find('input').val()
            if (!radio.dataset.isset) {
              radio.dataset.isset = true;
              $(radio).on("focusin", function () {
                let msg = 'languageSelector.languages.'+ val
                // console.log(val, msg, $(radio))
                vm.highlightParentLabel;
                vm.escucharAudio(vm.$t(msg));
              });
            }
          });
        }
      }
      this.customAccesibilityTabs.setup(this.$options.name, "#languageOptions");
    },

    highlightParentLabel(event) {
      let $el = $(event.target);
      let $radio = $el.find("input");
      this.languageValue = $radio.val();
    },

    /** Overwrite the default behaviour from the mixin */
    handleKeystroke(event) {
      switch (event.code) {
        case "Space":
        case "Enter":
          $(event.target).click()
          // this.setSelectedLanguage(this.languageValue)
          break;
        case "Escape":
          this.close();
          break;
        default:
          break;
      }
    }
  },
});
