// Requiere que ya esté cargado vuex en la instancia de Vue

import VueI18n from 'vue-i18n'

import Contexts from 'js/vue-plugins/vue-i18n/contexts'

import buildMessageObject from './build-message-object'

const availableLocales = Vue.store.state.languages
const locale = Vue.store.state.language
const fallbackLocale = Vue.store.state.fallbackLocale

Vue.use(VueI18n)

const messages = {}

Contexts.forEach(context => {
  context.keys().forEach(filename => {
    buildMessageObject(context, filename, availableLocales, messages)
  })
})

const i18n = new VueI18n({
  locale,
  fallbackLocale,
  // Congelamos el objeto para que vue no aplique reactividad a él
  messages: Object.freeze(messages)
})

Vue.i18n = i18n

export default i18n
