import Vue from 'vue'
import {mapGetters} from 'vuex'

import template from './_municipality-selector.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  computed: {
    ...mapGetters(['homeRoute', 'canSelectMunicipality'])
  },

  methods: {
    goToHome() {
      window.location.replace('/')
      // this.$emit('click')
      // this.$router.push(this.homeRoute)
    }
  }
})
