export default {
  /*
   * clientMediaInfo() devuelve información de los medios del usuario
   */
  clientMediaInfo(params, skipLoading = false) {
    return Vue.http.get('api_clients/client_media_info', {params, skipLoading}).then(
      response => response.data
    )
  },
  /*
   * existsAccount() comprueba si existe una cuenta
   */
  existsAccount(params) {
    return Vue.http.get('api_clients/exists_account', {params}).then(response => response.data.data)
  },
  /*
   * getClient() obtiene los datos de un cliente de everilion
   */
  getClient(params) {
    return Vue.http.get('api_clients/get_client', {params}).then(response => response.data.data)
  },
  /*
   * registerClient() registra un cliente en Everilion
   */
  registerClient(params) {
    return Vue.http.post('api_clients/register_client', params).then(response => response.data.data)
  },
  /*
   * validateClientTSC() valida la TSC de un usuario
   */
  validateClientTSC(params) {
    return Vue.http.post('api_clients/validate_client_tsc', params).then(
      response => response.data.data
    )
  },
  /*
   * clientAccount() obtiene los datos de la cuenta del usuario
   */
  clientAccount(params) {
    return Vue.http.get('api_clients/client_account', {params}).then(response => response.data.data)
  },
  /*
   * updateClient() actualiza los datos de la cuenta del usuario
   */
  updateClient(params) {
    return Vue.http.post('api_clients/update_client', params).then(
      response => response.data.data,
      response => Promise.reject(response.data.data)
    )
  },
  /*
   * getClientTransactions() devuelve una lista de las transacciones del usuario
   */
  getClientTransactions(params) {
    return Vue.http.get('api_clients/client_transactions', {params}).then(
      response => response.data.data
    )
  },
  /*
   *  unsuscribeQr() Desactivación del QR
   */
  unsuscribeQr(params, skipLoading = false) {
    return Vue.http.get('api_clients/unsuscribe_qr', {params, skipLoading}).then(
      response => response.data.data
    )
  }
}
