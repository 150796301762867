export default ({
  state: {
    mapEngine: MAP_ENGINE,
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    osmUrl: OSM_URL,
    osmAttribution: "Map data &copy; <a href='https://www.openstreetmap.org/'>OpenStreetMap</a> contributors, <a href='https://creativecommons.org/licenses/by-sa/2.0/'>CC-BY-SA</a>" // eslint-disable-line
  },
  getters: {
    mapEngine: state => state.mapEngine,
    googleMapsApiKey: state => state.googleMapsApiKey,
    osmUrl: state => state.osmUrl,
    osmAttribution: state => state.osmAttribution
  }
})
