export default ({
  state: {
    showProfileAccreditationRequests: false
  },
  mutations: {
    SET_SHOW_PROFILE_ACCREDITATION_REQUEST(state, value) {
      state.showProfileAccreditationRequests = value
    }
  },
  getters: {
    showProfileAccreditationRequests: state => state.showProfileAccreditationRequests
  },
  actions: {
    showViewProfileAccreditationRequest({commit}) {
      commit('SET_SHOW_PROFILE_ACCREDITATION_REQUEST', true)
    },

    closeViewProfileAccreditationRequest({commit}) {
      commit('SET_SHOW_PROFILE_ACCREDITATION_REQUEST', false)
    }
  }
})
