import Vue from 'vue'
Vue.filter('moverickCurrency', value => {
  // Si no hay valor devolvemos lo que nos llegue
  if (value === undefined || value === null) return value

  const config = Vue.store.getters.administrativeArea.config
  if (!config) return value

  // Pasamos el valor de la unidad mínima a la de enteros
  value /= 10.0 ** config.decimals_count

  // Aquí guardaremos los cambios realizados al valor
  let newValue = ''
  // Convertimos al número de decimales asignado
  newValue = value.toFixed(config.decimals_count).toString()
  // Reemplazamos el . de los decimales por el signo correspondiente(, o .)
  newValue = newValue.replace('.', config.decimals_separator)
  // Añadimos el simbolo correspondiente (, o .) al separador de miles
  newValue = newValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${config.thousands_separator}`)

  // Se añade el simbolo de moneda delante o detras según esté configurado
  if (config.currency_symbol_position === 'before') {
    return `${config.currency_symbol}${newValue}`
  }

  return `${newValue}${config.currency_symbol}`
})

Vue.filter('moverickCurrencyOnlyInteger', value => {
  // Si no hay valor devolvemos lo que nos llegue
  if (value === undefined || value === null) return value

  const config = Vue.store.getters.administrativeArea.config
  if (!config) return value

  // Pasamos el valor de la unidad mínima a la de enteros
  value = Math.floor(value / 10.0 ** config.decimals_count)

  // Aquí guardaremos los cambios realizados al valor
  let newValue = ''

  newValue = value.toString().replace('$', '')
  newValue = newValue.replace(config.thousands_separator, '')

  // Añadimos el simbolo correspondiente (, o .) al separador de miles
  newValue = newValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${config.thousands_separator}`)

  if (newValue === '') {
    newValue = 0
  }

  // Se añade el simbolo de moneda delante o detras según esté configurado
  if (config.currency_symbol_position === 'before') {
    return `${config.currency_symbol}${newValue}`
  }

  return `${newValue}${config.currency_symbol}`
})
