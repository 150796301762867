export default {
  /*
   * index() lista los municipios
   */
  async index(params) {
    const {data} = await Vue.http.get('topology/app_municipalities/home', {params})
    return data
  },

  /*
   * showSlug() obtiene un municipio con un slug compuesto del aplha2 del país y el nombre del
   * municipio
   */
  showSlug(municipality, params) {
    const country = Vue.store.state.country.alpha2.toLowerCase()

    return Vue.http.get(
      `topology/municipalities/${country}/${municipality}`, {params}
    ).then(response => response.data.data)
  }
}
