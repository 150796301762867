import Vue from 'vue'
import template from 'js/components/cities-select/index.pug'

import ListLayout from './list-layout'

Vue.component('cities-select', {
  name: 'cities-select-custom-vz',
  template: template(),

  components: {
    ListLayout
  }
})
