/* eslint-disable no-use-before-define */
import LocationService from 'js/services/location.js'

const TEN_SECONDS = 10 * 1000

export default {
  state: {
    userPosition: null,
    watchPositionId: null,
    positionErrorTimeout: null,
    shouldShowError: true
  },
  getters: {
    watchPositionId(state) {
      return state.watchPositionId
    },
    userPosition(state) {
      return state.userPosition
    },
    shouldEnqueuePositionErrorHandler(state) {
      const {shouldShowError, positionErrorTimeout} = state
      return shouldShowError && !positionErrorTimeout
    }
  },
  mutations: {
    setUserPosition(state, position) {
      const {coords: {latitude, longitude}} = position

      state.userPosition = {
        lat: latitude,
        lng: longitude
      }
    },
    removeUserPosition(state) {
      state.userPosition = null
    },
    resetPositionError(state) {
      clearTimeout(state.positionErrorTimeout)
      state.positionErrorTimeout = null
      state.shouldShowError = true
    },
    setShouldShowError(state, value) {
      state.shouldShowError = value
    },
    setPositionErrorTimeout(state, id) {
      state.positionErrorTimeout = id
    },
    setWatchPositionId(state, id) {
      state.watchPositionId = id
    }
  },
  actions: {
    watchUserPosition({commit, dispatch}) {
      const watchPositionId = LocationService.watch(
        position => {
          handleWatchUserPositionSuccess({commit}, position)
        },
        error => {
          handleWatchUserPositionError({commit, dispatch}, error)
        }
      )

      commit('setWatchPositionId', watchPositionId)
    },
    clearWatchUserPosition({commit, getters}) {
      const {watchPositionId} = getters

      LocationService.clearWatch(watchPositionId)

      commit('setWatchPositionId', null)
    },
    enqueuePositionErrorHandler({commit, getters}) {
      const {shouldEnqueuePositionErrorHandler} = getters

      if (shouldEnqueuePositionErrorHandler) {
        const timeoutId = setTimeout(
          positionErrorHandler.bind(null, commit),
          TEN_SECONDS
        )

        commit('setPositionErrorTimeout', timeoutId)
      }
    }
  }
}

function handleWatchUserPositionSuccess({commit}, position) {
  commit('setUserPosition', position)
  commit('resetPositionError')
}

function handleWatchUserPositionError({commit, dispatch}, error) {
  const {code, PERMISSION_DENIED} = error

  if (code === PERMISSION_DENIED) {
    commit('removeUserPosition')
  } else {
    dispatch('enqueuePositionErrorHandler')
  }
}

function positionErrorHandler(commit) {
  commit('setShouldShowError', false)

  Vue.notifications.send({
    id: 'position-error',
    text: Vue.i18n.t('errors.noPosition'),
    type: 'error',
    close: true
  })
}
