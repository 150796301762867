import Vue from 'vue'
import template from './index.pug'

// @vue/component
Vue.component('privacy-policy-text', {
  name: 'privacy-policy-text',
  template: template(),
  computed: {
    sections() {
      return this.getSections('privacyPolicyText')
    },
    te() {
      return this.$te.bind(this)
    }
  },
  methods: {
    getSections(rootSectionName) {
      const sections = []

      for (let index = 1; ; index++) {
        const section = this.getSection(rootSectionName, index)

        if (!section) { break }

        sections.push(section)
      }

      return sections
    },
    getSection(rootSectionName, index) {
      const {te} = this

      const sectionName = `${rootSectionName}.section-${index}`

      if (!te(sectionName)) {
        return null
      }

      const listName = `${sectionName}.list`
      const hasList = te(listName)

      const section = {
        name: sectionName,
        title: te(`${sectionName}.title`),
        list: hasList,
        listSections: hasList ? this.getSections(listName) : [],
        paragraphs: this.getParagrahps(sectionName)
      }

      return section
    },
    getParagrahps(sectionName) {
      const {te} = this

      const paragraphs = []

      for (let pIndex = 1; ; pIndex++) {
        const paragraphKey = `${sectionName}.paragraph-${pIndex}`

        if (!te(paragraphKey)) { break }

        paragraphs.push(paragraphKey)
      }

      return paragraphs
    }
  }
})
