import Vue from 'vue'
import { mapGetters } from 'vuex'
import template from './index.pug'

export default Vue.extend({
  name: 'video-embed',
  template: template(),

  props: {
    id: {
      required: false,
      type: String
    },
    width: {
      required: false,
      default: 560,
      type: Number
    },
    height: {
      required: false,
      default: 315,
      type: Number
    },
    src: {
      required: true,
      type: String
    },
    autoplay: {
      required: false,
      default: true,
      type: Boolean
    },
    controls: {
      type: Boolean,
      required: false,
      default: true
    },
    captions: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  
  computed: {
    ...mapGetters(["getUserLanguage"]),

    videoSrc(){
      let options = [`cc_lang_pref=${this.getUserLanguage}`]
      let url = this.src
      options.push(`autoplay=${this.autoplay ? "1" : "0"}`)
      options.push(`controls=${this.controls ? "1" : "0"}`)
      options.push(`cc_load_policy=${this.captions ? "1" : "0"}`)

      return url.includes('?') 
        ? `${url}&${options.join('&')}`
        : `${url}?${options.join('&')}`
    }
  }
  
})