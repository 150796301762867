import Vue from 'vue'

const basePath = 'document_identify_type'

export default {
  // index() obtiene todos los document_identify_types
  index(params) {
    return Vue.http.get(`${basePath}`, {params}).then(response => response.data)
  },
  
  // show() obtiene un document_identify_types
  show(id, params) {
    return Vue.http.get(`${basePath}/${id}`, {params}).then(response => response.data.data)
  }
}
