import Vue from 'vue'
import {mapActions} from 'vuex'

import FullHeader from 'js/components/layout-menu/header/_full-header.js'
import PortableHeader from 'js/components/layout-menu/header/_portable-header.js'

import template from './index.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  components: {
    FullHeader, PortableHeader
  },

  props: {
    controlMenu: {
      type: Object,
      required: true
    }
  },

  methods: {
    ...mapActions(['openLanguageModal']),

    openSidebarMenu() {
      this.controlMenu.toggle()
    },

    logout() {
      this.closeMenu()
      this.$emit('logout')
    },

    login() {
      this.closeMenu()
      this.$emit('login')
    },

    register() {
      this.closeMenu()
      this.$emit('register')
    },

    closeMenu() {
      this.$emit('close-menu')
    }
  }
})
