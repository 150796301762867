/*
 *    34535.539 -> 34.535,54
 *    34535.534534 -> 34.535,53
 *    34535 -> 34.535
 *    -34535 -> -34.535
 */
Vue.filter('moverickNumber', (value, options = {}) => {
  const finalOptions = _.merge({
    precision: 2,
    format: '%v',
    integerPrecision: true
  }, options)

  // BUG: quita 0s si el número es entero y termina en ceros
  return Vue.options.filters.currency(value, finalOptions).replace(/\.?0+$/, '')
})
