/* eslint-disable linebreak-style */
import Vue from 'vue'
import {SOCIAL_MEDIA, DEFAULT} from 'js/lib/info.js'

import {mapGetters} from 'vuex'

import template from './index.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  data() {
    return {
      default: DEFAULT,
      socialMediaItems: SOCIAL_MEDIA
    }
  },
  computed: {
    ...mapGetters(['currentMunicipality']),

    socialMediaItemsByArea() {
      let socialMediaItemsAux = []
      let auxSocialMedia = null
      if (this.currentMunicipality && this.currentMunicipality.code &&
        Number.isInteger(Number(this.currentMunicipality.code))) {
        auxSocialMedia = this.socialMediaItems
          .filter(socialMedia => socialMedia.scope ===
            Number(this.currentMunicipality.code))
        if (auxSocialMedia && auxSocialMedia.length > 0)socialMediaItemsAux = auxSocialMedia[0]
      } else {
        auxSocialMedia = this.socialMediaItems
          .filter(socialMedia => socialMedia.scope === this.default)
        if (auxSocialMedia && auxSocialMedia.length > 0)socialMediaItemsAux = auxSocialMedia[0]
      }
      if (!auxSocialMedia) {
        socialMediaItemsAux = this.socialMediaItems
      }

      return socialMediaItemsAux
    }
  }
})
