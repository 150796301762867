import Vue from 'vue'
import CityImage from 'assets/images/cities/icons/city.svg'

import template from './index.pug'

export default Vue.extend({
  template: template(),

  props: {
    municipality: {
      type: Object,
      required: true
    }
  },

  computed: {
    image() {
      return this.municipality.image_url || CityImage
    },

    name() {
      return this.municipality.valid_name_for_client
    },

    description() {
      return this.municipality.description_for_client
    },

    highlighted() {
      return this.municipality.highlighted
    },

    nameNormalized() {
      return this.municipality.name_normalized
    },

    country() {
      return this.municipality.country_alpha2
    }
  },

  methods: {
    goToCity() {
      this.$router.push({
        name: 'municipality',
        params: {country: this.country, municipality: this.nameNormalized}
      })
    }
  }
})
