import Vue from 'vue'
import template from './index.pug'

// @vue/component
Vue.component('user-position-map-button', {
  template: template(),

  props: {
    position: {
      type: Object,
      default: undefined
    }
  },

  methods: {
    emitClick() {
      this.$emit('click', this.position)
    }
  }
})
