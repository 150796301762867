import Vue from 'vue'
import {mapGetters} from 'vuex'
import {COMPLAINTS_URL} from 'js/lib/info.js'

import template from './index.pug'

const ComplaintsBook = require('assets/images/ilustrations/links/complaints-book.png')
const libroReclamoBlanco = require('assets/images/ilustrations/links/libroReclamoBlanco.svg')

// @vue/component
export default Vue.extend({
  template: template(),
 
  data() {
    return {
      complaintsUrl: COMPLAINTS_URL,
      complaintsBook: libroReclamoBlanco
    }
  },

})
