function asyncLoadMunicipalities() {
  return new Promise((resolve, _reject) => {
    if (Vue.store.getters.noMunicipalityLoaded) {
      Vue.store.dispatch('loadAvailableMunicipalities').then(() => {
        resolve(true)
      })
    } else {
      resolve(true)
    }
  })
}

function selectFirstMunicipality() {
  if (Vue.store.getters.noMunicipalitySelected) {
    const municipality = Vue.store.getters.firstAvailableMunicipality
    Vue.store.dispatch(
      'selectMunicipality', {
        countryAlpha2: municipality.country_alpha2, municipalityName: municipality.name_normalized
      }
    )
  }
}

function goToHomeRoute(to, from, next) {
  // Si voy a una ruta prohibida para 1 municipio
  if (to.meta.forbiddenWithOneMunicipality) {
    next(Vue.store.getters.homeRoute)
  // Voy a una ruta normal
  } else {
    next()
  }
}

function municipalities(to, from, next) {
  // Cargamos municipios si no tenemos
  asyncLoadMunicipalities().then(() => {
    // Puedo seleccionar más de un municipio
    if (Vue.store.getters.canSelectMunicipality) {
      next()
    // No puedo seleccionar más de un municipio
    } else {
      // Seleccionar el primero si no lo tengo seleccionado
      selectFirstMunicipality()

      // Redirige a la ruta home apropiada
      goToHomeRoute(to, from, next)
    }
  })
}

export default municipalities
