import Vue from 'vue'
import {TELEPHONE} from 'js/lib/info.js'
//import ico_telefono from 'assets/images/ico-telefono.svg'
import template from './index.pug'
const ico_telefono = require('../../../assets/images/ico-telefono.svg')

// @vue/component
export default Vue.extend({
  template: template(),

  data() {
    return {
      telephone_href: TELEPHONE.href,
      telephone_number: TELEPHONE.number,
      rutatelefono:ico_telefono
    }
  },
  computed: {
    image() {
      //console.log("sss",ico_telefono)
      return  ico_telefono
    },
  },
  created(){
    //console.log("*-------------",this.$t('defaultLayout.mesajeTelefono'))

  }
})
